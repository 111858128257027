<template>
    <div class="main-pri">
        <div class="head-main">
            <div id="toolbox" class='left vertical-control'>
                <a :style="`${!showComposeButton ? 'visibility: hidden;cursor: no-drop;' : ''}`" href="javascript:;"
                    class="btn-compose button-hover focusable"
                    @click="$root.$emit('handleNavbarAction', EMAIL_ACTION.COMPOSE)">
                    <img src="@/assets/zimbra/images/ic-edit.svg" />
                    {{ $t("zimbra.zhMsg.compose") }}
                </a>
                <div class="box-menu-head zm-cursor-no-drop" v-if="showButtonAction">
                    <ul>
                        <li v-if="isNewWindow" class="item-menu-head zm-icon-fi" @click="oncloseNewWindow">
                            <a href="javascript:;" class="focusable">
                                <i class="fi fi-rr-cross"></i>
                                <span>{{ $t("zimbra.zhMsg.close") }}</span>
                            </a>
                        </li>
                        <li v-if="isDraft" :class="['item-menu-head', isRssFolder ? 'disabled' : '']">
                            <a href="javascript:;" :class="actionDisable[EMAIL_ACTION.DRAFT] ? 'noclick' : 'focusable'"
                                @click="$root.$emit('handleNavbarAction', EMAIL_ACTION.EDIT_AS_NEW)">
                                <i :class="renderSkinCurrent ? 'ic ic-edit-email-vmail' : 'ic ic-edit-email'"></i>
                                <span>{{ $t("zimbra.zhMsg.edit") }}</span>
                            </a>
                        </li>
                        <li :class="`item-menu-head ${isRssFolder ? 'disabled' : ''}`">
                            <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zmMsg.replyTooltip')} [r]`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.REPLY] ? 'noclick' : 'focusable']"
                                    href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.REPLY)">
                                    <i :class="renderSkinCurrent ? 'ic ic-reply-vmaill' : 'ic ic-reply'"></i>
                                    <span>{{ $t("zimbra.zhMsg.actionReplyByEmail") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                        <li :class="`item-menu-head ${isRssFolder ? 'disabled' : ''}`">
                            <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zmMsg.replyAllTooltip')} [a]`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.REPLY_ALL] ? 'noclick' : 'focusable']"
                                    href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.REPLY_ALL)">
                                    <i :class="renderSkinCurrent ? 'ic ic-replyall-vmail' : 'ic ic-replyall'"></i>
                                    <span>{{ $t("zimbra.zhMsg.replyAll") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                        <li class="item-menu-head">
                            <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zmMsg.forwardTooltip')} [f]`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.FORWARD] ? 'noclick' : 'focusable']"
                                    href="javascript:;" @click=" handleNavbarAction(EMAIL_ACTION.FORWARD)">
                                    <i :class="renderSkinCurrent ? 'ic ic-forward-vmaill' : 'ic ic-forward'"></i>
                                    <span>{{ $t("zimbra.zhMsg.actionForwardByEmail") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                        <li :class="['item-menu-head', isTablet === true ? 'd-none' : '']"
                            v-if="listSelectedMail[0] && !checkFlagsContainKey(listSelectedMail[0].f, EMAIL_FLAG.UNREAD)">
                            <a :class="[
                                actionDisable[EMAIL_ACTION.MARK_AS_UNREAD] ? 'noclick' : 'focusable',
                                isTablet === true ? 'd-none' : ''
                            ]" href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.MARK_AS_UNREAD)">
                                <i :class="renderSkinCurrent ? 'ic ic-unread-vmail' : 'ic ic-mail'"></i>
                                <span>{{ $t("zimbra.zhMsg.mark_read_unread") }}</span>
                            </a>
                        </li>
                        <li :class="['item-menu-head', isTablet === true ? 'd-none' : '']"
                            v-if="listSelectedMail[0] && checkFlagsContainKey(listSelectedMail[0].f, EMAIL_FLAG.UNREAD)">
                            <a :class="[
                                actionDisable[EMAIL_ACTION.MARK_AS_READ] ? 'noclick' : 'focusable',
                                isTablet === true ? 'd-none' : ''
                            ]" href="javascript:;" @click="
                                handleNavbarAction(
                                    EMAIL_ACTION.MARK_AS_READ
                                )
                                ">
                                <i :class="renderSkinCurrent ? 'ic ic-mail-vmail' : 'ic ic-mail'"></i>
                                <span>{{
                                    $t("zimbra.zhMsg.mark_read_unread")
                                }}</span>
                            </a>
                        </li>
                        <li v-if="!isNewWindow" class="item-menu-head">
                            <el-tooltip :open-delay="1000"
                                :content="`${this.$t('zimbraNokey.zimbra_zimbraNokey_archiveTooltip')}`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.ARCHIVE] ? 'noclick' : 'focusable', isTablet === true ? 'd-none' : '']"
                                    href="javascript:;" @click=" handleNavbarAction(EMAIL_ACTION.ARCHIVE)">
                                    <i :class="renderSkinCurrent ? 'ic ic-archive-vmail' : 'ic ic-archive'"></i>
                                    <span>{{ $t("zimbraNokey.zimbra_mailbox_archive") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                    </ul>
                    <ul :class="[isTablet === true ? 'd-none' : '']">
                        <el-popover v-model="visablePopoverMove" placement="bottom-start" width="auto" trigger="click"
                            popper-class="zimbra-popper" :disabled="actionDisable[EMAIL_ACTION.MOVE]">
                            <PopoverActionMove></PopoverActionMove>
                            <li class="item-menu-head" ref="nodeMove" slot="reference">
                                <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zhMsg.actionMoveTT')}`"
                                    placement="bottom" effect="light">
                                    <a href="javascript:;"
                                        :class="[actionDisable[EMAIL_ACTION.MOVE] ? 'noclick' : 'focusable down-enter']">
                                        <i :class="renderSkinCurrent ? 'ic ic-move-vmail' : 'ic ic-move'"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionGo") }}<i class="far fa-angle-down"></i></span>
                                    </a>
                                </el-tooltip>
                            </li>
                        </el-popover>
                        <li class="item-menu-head">
                            <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zmMsg.printMultiTooltip')}`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.PRINT] ? 'noclick' : 'focusable', isTablet === true ? 'd-none' : '']"
                                    href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.PRINT)">
                                    <i :class="renderSkinCurrent ? 'ic ic-print-vmail' : 'ic ic-print'"></i>
                                    <span>{{ $t("zimbra.zhMsg.actionPrint") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                    </ul>
                    <ul class="zm-cursor-no-drop">
                        <li v-if="!isFolder(FOLDER_DEFAULT.JUNK)" class="item-menu-head">
                            <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zhMsg.actionSpamTT')} [ms]`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.SPAM] ? 'noclick' : 'focusable', isTablet === true ? 'd-none' : '']"
                                    href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.SPAM)">
                                    <i :class="renderSkinCurrent ? 'ic ic-spam-vmail' : 'ic ic-spam1'"></i>
                                    <span>{{ $t("zimbra.zhMsg.actionSpam") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                        <li v-if="isFolder(FOLDER_DEFAULT.JUNK)" class="item-menu-head">
                            <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zhMsg.actionNotSpamTT')} [ms]`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.SPAM] ? 'noclick' : 'focusable', isTablet === true ? 'd-none' : '']"
                                    href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.UNSPAM)">
                                    <i :class="renderSkinCurrent ? 'ic ic-spam-vmail' : 'ic ic-spam1'"></i>
                                    <span>{{ $t("zimbra.zhMsg.actionNotSpam") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                        <li class="item-menu-head" v-show="!folderArchive.hideDeleteButton">
                            <el-tooltip :open-delay="1000" :content="`${this.$t('zimbra.zhMsg.actionTrashTT')} [Del]`"
                                placement="bottom" effect="light">
                                <a :class="[actionDisable[EMAIL_ACTION.DELETE] ? 'noclick' : 'focusable']"
                                    href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.DELETE)">
                                    <i :class="renderSkinCurrent ? 'ic ic-trash-vmail' : 'ic ic-trash1'"></i>
                                    <span>{{ $t("zimbra.zhMsg.actionDelete") }}</span>
                                </a>
                            </el-tooltip>
                        </li>
                    </ul>
                </div>
                <div :class="[actionDisable[EMAIL_ACTION.MORE_ACTION] ? 'noclick' : '', 'control',]"
                    v-if="showMoreAction">
                    <el-popover v-model="visablePopover" placement="right" width="200" trigger="click"
                        popper-class="zimbra-popper" :show="focusElement('inboxAssignTag')">
                        <div class="zimbra-navbar">
                            <ul class="box-control-inbox horizontal-control">
                                <li class="sub">
                                    <a href="javascript:;" class="focusable" id="inboxAssignTag">
                                        <i class="ic ic-tag"></i>
                                        <span>{{ $t("zimbraNokey.zimbra_mailbox_assignTag") }}</span>
                                    </a>
                                    <ul>
                                        <perfect-scrollbar style=" max-height: 200px; height: auto; ">
                                            <template v-for="(item, idx) in listTagChoice">
                                                <el-tooltip :key="`tag_${idx}`" class="item" effect="dark"
                                                    :content="item.name.toString()" placement="top-start"
                                                    :open-delay="500">
                                                    <li class="zm-tag-item"
                                                        @click="actiondAssginOrRemoveTag(item, true)">
                                                        <a href="javascript:;" class="focusable">
                                                            <i class="ic ic-tag"
                                                                :style="`filter: ${convertHexToFilterCss(item)}`"></i>
                                                            <span>{{ item.name }}</span>
                                                        </a>
                                                    </li>
                                                </el-tooltip>
                                            </template>
                                        </perfect-scrollbar>
                                        <hr style="margin: 0px" />
                                        <li v-if="!isNewWindow" class="zm-tag-item" :key="`new_tag`"
                                            @click="$root.$emit('handleAddAssginTag')">
                                            <a href="javascript:;" class="focusable">
                                                <i class="ic-add-circle"></i>
                                                <span>{{ $t("zimbra.zhMsg.tagNew") }}</span>
                                            </a>
                                        </li>
                                        <li v-if="listTagAssgin.length == 1" class="zm-tag-item" :key="`remove_tag`"
                                            @click="handledAssginOrRemoveTag(listTagAssgin[0], false)">
                                            <a href="javascript:;" class="focusable">
                                                <i class="ic-close-circle"></i>
                                                <span>{{ $t("zimbra.zhMsg.actionRemoveTag") }}</span>
                                            </a>
                                        </li>
                                        <li></li>
                                        <li v-if="listTagAssgin.length != 1"
                                            :class="`zm-tag-item ${!listTagAssgin.length > 0 ? 'noclick' : 'sub'}`"
                                            :key="`remove_tag`">
                                            <a href="javascript:;"
                                                :class="`${!listTagAssgin.length > 0 ? '' : 'focusable'}`">
                                                <i class="ic-close-circle"></i>
                                                <span>{{ $t("zimbra.zhMsg.actionRemoveTag") }}</span>
                                            </a>
                                            <ul v-if="listTagAssgin">
                                                <perfect-scrollbar style=" max-height: 200px; height: auto; ">
                                                    <template v-for="( item, idx ) in listTagAssgin">
                                                        <el-tooltip :key="`tag_${idx}`" class="item" effect="dark"
                                                            :content="item.name.toString()" placement="top-start"
                                                            :open-delay="1000">
                                                            <li class="zm-tag-item" :key="`tag_${idx}`"
                                                                @click="actiondAssginOrRemoveTag(item, false)">
                                                                <a href="javascript:;" class="focusable">
                                                                    <i class="ic ic-tag"
                                                                        :style="`filter: ${convertHexToFilterCss(item)}`"></i>
                                                                    <span>{{ item.name }}</span>
                                                                </a>
                                                            </li>
                                                        </el-tooltip>
                                                    </template>
                                                </perfect-scrollbar>
                                                <hr style="margin: 0px" />
                                                <li class="zm-tag-item" :key="`remove_tag`"
                                                    @click="handledRemoveAllTag()">
                                                    <a href="javascript:;" class="focusable">
                                                        <i class="ic ic-tag"></i>
                                                        <span>{{ $t("zimbra.zmMsg.allTags") }}</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li :class="[actionDisable[EMAIL_ACTION.REDIRECT] ? 'noclick' : '']"
                                    @click=" $root.$emit('showDialogRedirect'); visablePopover = false;">
                                    <a href="javascript:;" class="focusable">
                                        <i class="ic ic-navigation"></i>
                                        <span>{{ $t("zimbra.zmMsg.mailRedirect") }}</span>
                                    </a>
                                </li>
                                <li @click="showOriginal">
                                    <a href="javascript:;" class="color-3F4254 focusable">
                                        <i class="ic ic-email-active"></i>
                                        <span>{{ $t("zimbra.zhMsg.showOrig") }}</span>
                                    </a>
                                </li>
                                <template
                                    v-if="isStar(mapppingStarConv, currentActiveEmail ? currentActiveEmail.id : '', 'cid')">
                                    <li @click=" handleNavbarAction(EMAIL_ACTION.UNSTAR); visablePopover = false;">
                                        <a href="javascript:;" class="color-3F4254 focusable">
                                            <i class="ic ic-star"></i>
                                            <span> {{ $t("zimbraNokey.zimbra_zimbraNokey_unstar") }} </span>
                                        </a>
                                    </li>
                                </template>
                                <template v-else>
                                    <li @click="handleNavbarAction(EMAIL_ACTION.STAR); visablePopover = false;">
                                        <a href="javascript:;" class="color-3F4254 focusable">
                                            <i class="ic ic-star"></i>
                                            <span>{{ $t("zimbraNokey.zimbra_mailbox_assignStar") }}</span>
                                        </a>
                                    </li>
                                </template>
                                <li
                                    v-if="listSelectedMail[0] && !checkFlagsContainKey(listSelectedMail[0].f, EMAIL_FLAG.UNREAD)">
                                    <a :class="[actionDisable[EMAIL_ACTION.MARK_AS_UNREAD] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '']"
                                        href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.MARK_AS_UNREAD)">
                                        <i class="ic ic-unread"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionMarkUnread") }}</span>
                                    </a>
                                </li>
                                <li
                                    v-if="listSelectedMail[0] && checkFlagsContainKey(listSelectedMail[0].f, EMAIL_FLAG.UNREAD)">
                                    <a :class="[actionDisable[EMAIL_ACTION.MARK_AS_READ] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '']"
                                        href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.MARK_AS_READ)">
                                        <i class="ic ic-mail"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionMarkRead") }}</span>
                                    </a>
                                </li>
                                <li v-if="!isNewWindow">
                                    <a :class="[actionDisable[EMAIL_ACTION.ARCHIVE] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '']"
                                        href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.ARCHIVE)">
                                        <i class="ic ic-archive"></i>
                                        <span>{{ $t("zimbraNokey.zimbra_mailbox_archive") }}</span>
                                    </a>
                                </li>
                                <li ref="nodeMove" slot="reference">
                                    <a href="javascript:;"
                                        @click="$root.$emit('handleSidebarActionMoveArchiveMail', FOLDER_EXTRA_ACTION.MOVE_MAIL)"
                                        :class="[actionDisable[EMAIL_ACTION.MOVE] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '']">
                                        <i class="ic ic-move"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionGo") }}</span>
                                    </a>
                                </li>
                                <li>
                                    <a :class="[actionDisable[EMAIL_ACTION.PRINT] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '']"
                                        href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.PRINT)">
                                        <i class="ic ic-print"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionPrint") }}</span>
                                    </a>
                                </li>
                                <li v-if="!isFolder(FOLDER_DEFAULT.JUNK)">
                                    <a :class="[actionDisable[EMAIL_ACTION.SPAM] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '']"
                                        href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.SPAM)">
                                        <i class="ic ic-spam1"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionSpam") }}</span>
                                    </a>
                                </li>
                                <li v-if="isFolder(FOLDER_DEFAULT.JUNK)">
                                    <a :class="[actionDisable[EMAIL_ACTION.SPAM] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '']"
                                        href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.UNSPAM)">
                                        <i class="ic ic-spam1"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionNotSpam") }}</span>
                                    </a>
                                </li>
                                <li v-show="!folderArchive.hideDeleteButton">
                                    <a :class="[actionDisable[EMAIL_ACTION.DELETE] ? 'noclick' : 'focusable', isTablet !== true ? 'd-none' : '']"
                                        href="javascript:;" @click="handleNavbarAction(EMAIL_ACTION.DELETE)">
                                        <i class="ic ic-trash1"></i>
                                        <span>{{ $t("zimbra.zhMsg.actionDelete") }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <button slot="reference" class="btn-other focusable down-enter">
                            <i :class="renderSkinCurrent ? 'ic other-icon-vmail' : 'ic other-icon'"></i>
                        </button>
                    </el-popover>
                </div>
            </div>
            <div v-if="showSearchBox" class="head-search mail-search d-flex">
                <el-tooltip :content="$t('zimbra.zmMsg.syncExternalMail')" class="check-mail-pref-update" effect="dark"
                    placement="top-start">
                    <a class="ic-refresh pr-3 focusable" @click="checkMailPrefUpdate"></a>
                </el-tooltip>
                <div class="form-group flex-grow-1">
                    <input type="text" class="form-control" :placeholder="$t('zimbraNokey.placeholderSearchInbox')"
                        v-model="hasTheWords" @focus="handleFocus" v-on:keyup.enter="handldeSearchKeyword()"
                        v-on:keyup.space="changeKeyWordSearch()" ref="searchbar" />
                    <button class="btn-search" @click="handldeSearchKeyword()">
                        <img src="@/assets/zimbra/images/ic-search1.svg" alt="" />
                    </button>
                    <el-popover v-model="visibleDialogSearchBox" placement="bottom" trigger="click">
                        <DialogSearchBox v-if="visibleDialogSearchBox" v-model="modelSearch"
                            @onclose="visibleDialogSearchBox = false" @onsearch="handleSearch" />
                        <a slot="reference" href="javascript:;" class="btn-search-advanced focusable">
                            <img src="@/assets/zimbra/images/ic-setting1.svg" alt="" />
                        </a>
                    </el-popover>
                </div>
                <a href="javascript:;" class="ic-search-mobile focusable"><i class="fal fa-search"></i></a>
            </div>
        </div>
    </div>
</template>
<script>
import {
    EMAIL_ACTION,
    EMAIL_FLAG,
    FOLDER_DEFAULT,
    FOLDER_EXTRA_ACTION,
    LIST_EMAIL_ACTION,
    RIGHTS_FOLDER,
    SCREEN_MODE
} from "@/utils/constants";
import $ from "jquery";
import { mapActions, mapGetters } from "vuex";
import EmailUtils from "@/utils/email-utils";
import CommonUtils from "@/utils/common-utils";
import PopoverActionMove from "./components/PopoverActionMove";
import DialogSearchBox from "./components/DialogSearchBox";
import _ from "lodash";
import hotkeys from "hotkeys-js";
import ZimbraMailService from "@/services/ZimbraMailService.js";

export default {
    mixins: [CommonUtils, EmailUtils],
    props: {
        isNewWindow: {
            type: Boolean,
            default: false
        },
        dataMailNewWindow: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            EMAIL_FLAG: EMAIL_FLAG,
            EMAIL_ACTION: EMAIL_ACTION,
            FOLDER_EXTRA_ACTION: FOLDER_EXTRA_ACTION,
            RIGHTS_FOLDER: RIGHTS_FOLDER,
            FOLDER_DEFAULT: FOLDER_DEFAULT,
            SCREEN_MODE: SCREEN_MODE,
            visablePopover: false,
            visablePopoverMove: false,
            nodeMove: "",
            visibleDialogSearchBox: false,
            refTimeoutSearch: null,
            hasTheWords: "",
            listTagAssgin: [],
            actionDisable: {},
            lastIconSelect: null,
            hasHandledUpdateDataExternal: true
        };
    },
    components: {
        PopoverActionMove,
        DialogSearchBox
    },
    computed: {
        ...mapGetters([
            "currentActiveEmail",
            "currentExtraActiveEmail",
            "listTag",
            "modelSearch",
            "showSearchStringCurrent",
            "mailInitialSearch",
            "activeNodeSidebar",
            "folderArchive",
            "listEmailSearch",
            "listSelectedMail",
            "mapppingStarConv",
            "isTablet",
            "skinCurrent"
        ]),
        isArchive() {
            const { m } = this.currentActiveEmail?.data ?? {};
            if (!m || (Array.isArray(m) && m.length === 0)) {
                return false;
            }
            const l = Array.isArray(m) ? m[0].l : m.l;
            return (
                this.folderArchive.id == this.activeNodeSidebar.id ||
                l == this.folderArchive.id
            );
        },
        listTagChoice() {
            return this.listTag.filter((x) => !this.listTagAssgin.includes(x));
        },
        isDraft() {
            const isOnlyOneMailDraft = this.listSelectedMail.length == 1 && this.listSelectedMail[0].l == FOLDER_DEFAULT.DRAFTS;
            return isOnlyOneMailDraft || (this.checkFlagsContainKey(
                this.currentActiveEmail?.f,
                EMAIL_FLAG.DRAFT
            ) && this.isFolder(FOLDER_DEFAULT.DRAFTS));
        },
        showComposeButton() {
            return this.$route.name != "Setting" && !this.isNewWindow;
        },
        showSearchBox() {
            return !this.isNewWindow;
        },
        // showButtonAction() {
        //     return (
        //         this.isNewWindow ||
        //         (this.$route.name != "Setting" &&
        //             this.currentActiveEmail &&
        //             this.currentActiveEmail.type === SCREEN_MODE.VIEW) || (this.listSelectedMail && this.listSelectedMail.length > 0 && this.$route.name != "Setting")
        //     );

        // },

        showButtonAction() {
            for (let item of this.listTag) {
                if (item.data?.isAddNew === true && item.isActive === true) {
                    return false;
                }
            }
            return (
                this.isNewWindow ||
                (this.$route.name != "Setting" &&
                    this.currentActiveEmail &&
                    this.currentActiveEmail.type != SCREEN_MODE.COMPOSE && this.currentActiveEmail.type != SCREEN_MODE.DRAFT) ||
                (this.listSelectedMail && this.listSelectedMail.length > 0 && this.$route.name != "Setting")
            );
        },


        // showMoreAction() {
        //     return (
        //         this.isNewWindow ||
        //         (this.$route.name != "Setting" &&
        //             this.currentActiveEmail &&
        //             this.currentActiveEmail.type === SCREEN_MODE.VIEW) || (this.listSelectedMail && this.listSelectedMail.length > 0 && this.$route.name != "Setting")
        //     );
        // },
        showMoreAction() {
            return (
                this.isNewWindow ||
                (this.$route.name != "Setting" &&
                    this.currentActiveEmail && this.currentActiveEmail.type != SCREEN_MODE.COMPOSE && this.currentActiveEmail.type != SCREEN_MODE.DRAFT)
            );
        },
        isRssFolder() {
            return !!this.activeNodeSidebar?.url;
        },
        renderSkinCurrent() {
            if ((this.skinCurrent.content == "carbon" || this.skinCurrent.content == "beach") && !this.isNewWindow) {
                return true;
            }
            return false;
        }
    },
    watch: {
        currentActiveEmail: {
            deep: true,
            handler: function (val) {
                if (val) {
                    this.listTagAssgin = this.getListTag(val);
                    this.updateDisabledButton();
                }
            }
        },
        listEmailSearch: {
            deep: true,
            handler: function (val) {
                if (this.currentActiveEmail) {
                    const _email = val.find(
                        (e) => e.id === this.currentActiveEmail.id
                    );
                    this.listTagAssgin = this.getListTag(_email);
                }
            }
        },
        listSelectedMail: {
            deep: true,
            handler() {
                // cập nhật trạng thái disabled button
                if (this.listSelectedMail.length === 1) {
                    this.updateDisabledButton();
                } else {
                    LIST_EMAIL_ACTION.forEach((e) => {
                        this.actionDisable[e] = true;
                    });
                    const enableField = this.isDraft
                        ? [
                            EMAIL_ACTION.ARCHIVE,
                            EMAIL_ACTION.DELETE,
                            EMAIL_ACTION.PRINT,
                            EMAIL_ACTION.MORE_ACTION,
                            EMAIL_ACTION.ASSIGN_TAG,
                            EMAIL_ACTION.SHOW_ORIGINAL,
                            EMAIL_ACTION.STAR,
                            EMAIL_ACTION.UNSTAR
                        ]
                        : [
                            EMAIL_ACTION.FORWARD,
                            EMAIL_ACTION.ARCHIVE,
                            EMAIL_ACTION.MARK_AS_UNREAD,
                            EMAIL_ACTION.MARK_AS_READ,
                            EMAIL_ACTION.MOVE,
                            EMAIL_ACTION.SPAM,
                            EMAIL_ACTION.UNSPAM,
                            EMAIL_ACTION.MORE_ACTION,
                            EMAIL_ACTION.DELETE,
                            EMAIL_ACTION.PRINT
                        ];
                    enableField.forEach((e) => {
                        this.actionDisable[e] = false;
                    });
                    this.actionDisable[EMAIL_ACTION.ARCHIVE] =
                        this.actionDisable[EMAIL_ACTION.ARCHIVE] || this.isArchive;
                    this.actionDisable = _.cloneDeep(this.actionDisable);
                }
            }
        },
        "showSearchStringCurrent.content": {
            immediate: true,
            handler(val) {
                if (val) {
                    this.hasTheWords = this.mailInitialSearch?.content;
                }
            }
        }
    },
    mounted() {
        this.$root.$on("fillModelToSearch", (query) => {
            if (query.query) {
                const keywords = query.query.split(/\s(?=(?:[^"]*"[^"]*")*(?![^"]*"))/);

                // Extract data
                const check = {
                    tocc: keywords.find(str => str.startsWith("to:"))?.split(":")[1],
                    subject: keywords.find(str => str.startsWith("subject:"))?.split(":")[1],
                    from: keywords.find(str => str.startsWith("from:"))?.split(":")[1]
                };
                this.$store.commit("SET_MODEL_SEARCH", check);

            } else {
                this.$store.commit("SET_MODEL_SEARCH", null);
            }

        });
        // listen close active mail
        this.$root.$on("closeHeader", () => { // here you need to use the arrow function
            // this.listSelectedMail = [];
            this.$store.commit("SET_LIST_SELECTED_MAIL", []);

            this.lastIconSelect = null;
        });
        this.$root.$on("pushTagToMailOpen", (mailUpdate) => {
            this.listTagAssgin.push(mailUpdate.tag);
        });
        this.$root.$on("deleteTagToMailOpen", (mailUpdate) => {
            this.listTagAssgin = this.listTagAssgin.filter(x => x.id != mailUpdate.tag.id);
        });
        this.$root.$on("closePopoverNavbar", () => {
            this.handleFocusOut();
        });
        this.$root.$on("handleSearchEmailFromContact", (obj) => {
            const formData = {
                header: obj.data.header,
                fromOrToContact: obj.data.email,
                sortBy: obj.data.sortBy,
                tz: obj.data.tz,
                locale: obj.data.locale,
                type: obj.type,
                recip: "0"
            };
            this.handleSearch(formData);
        });
        this.$root.$on("handleSearchFromSender", () => {
            const data = { from: this.currentExtraActiveEmail.e[0].a };
            this.handleSearch(data);
        });
        this.$root.$on("handleSearchToSender", () => {
            const data = { tocc: this.currentExtraActiveEmail.e[0].a };
            this.handleSearch(data);
        });
        this.$root.$on("handleSearchFromRecipient", () => {
            let currentExtraActiveEmail = JSON.stringify(this.currentExtraActiveEmail)
            currentExtraActiveEmail = JSON.parse(currentExtraActiveEmail)
            const data = { from: currentExtraActiveEmail?.to[0]?.a };
            this.handleSearch(data);
        });
        this.$root.$on("handleSearchToRecipient", () => {
            let currentExtraActiveEmail = JSON.stringify(this.currentExtraActiveEmail)
            currentExtraActiveEmail = JSON.parse(currentExtraActiveEmail)
            const data = { tocc: currentExtraActiveEmail?.to[0]?.a };
            this.handleSearch(data);
        });
        this.$root.$on("clickMoveMailToFolder", (data) => {
            this.handledMoveMail(data);
        });
        this.$root.$on("handleShowSearchString", (data) => {
            if (data) this.hasTheWords = this.mailInitialSearch.content;
            else this.hasTheWords = "";
        });
        this.$root.$on("setHasTheWords", (content) => {
            // this.hasTheWords = content;
        });

        // Xóa phần tô sáng kết quả tìm kiếm
        this.$root.$on("handleClearSearchHighlights", this.handleFocusSearchInput);

        hotkeys("/", this.handleFocus);
        hotkeys("n+s", this.handleShowSearchBox);
        hotkeys("t", this.handlePressNextHotKey);
        hotkeys("u", this.handledRemoveTag);
        hotkeys("Right", this.handleNext);
        hotkeys("Left", this.handlePrevious);
        /*collapse search mobile*/
        setTimeout(() => {
            var win = $(window);
            if ($(win).width() < 1260) {
                if ($(".ic-search-mobile").length) {
                    $(".ic-search-mobile").on("click", function () {
                        $(this).children("i").toggleClass("fa-search fa-times");
                        $(".main-pri .head-search .form-group, .main-pri .head-search").toggleClass("show");
                    });
                }
            }
        }, 200);
    },
    created() {
        // this.initKeywordSearch();
        if (this.isNewWindow) {
            this.listTagAssgin = this.getListTag(this.dataMailNewWindow);
        }
    },
    methods: {
        handleNext() {
            const allItemMenu = document.querySelectorAll("#toolbox > div.box-menu-head.zm-cursor-no-drop > ul .item-menu-head > a,#toolbox > div.control > span > span > button");
            if (this.lastIconSelect != null) {
                if (this.lastIconSelect < allItemMenu.length - 1) {
                    this.lastIconSelect++;
                } else {
                    this.lastIconSelect = 0;
                }

                allItemMenu[this.lastIconSelect].focus();
            } else {
                this.lastIconSelect = 0;
                allItemMenu[this.lastIconSelect].focus();
            }

        },
        handlePrevious() {
            const allItemMenu = document.querySelectorAll("#toolbox > div.box-menu-head.zm-cursor-no-drop > ul .item-menu-head > a,#toolbox > div.control > span > span > button");
            if (this.lastIconSelect != null) {
                if (this.lastIconSelect > 0) {
                    this.lastIconSelect--;
                } else {
                    this.lastIconSelect = allItemMenu.length - 1;
                }

                allItemMenu[this.lastIconSelect].focus();
            } else {
                this.lastIconSelect = allItemMenu.length - 1;
                allItemMenu[this.lastIconSelect].focus();
            }

        },
        handledRemoveTag() {
            if (this.listSelectedMail && this.listSelectedMail.length > 0 && this.listTagAssgin.length > 0) {
                this.handledRemoveAllTag();
            }
        },
        handleShowSearchBox() {
            this.$store.commit("SET_SHOW_SEARCH_BOX", true);
            this.$store.commit("SET_LIST_EMAIL", []);
        },
        handlePressNextHotKey() {

        },
        changeKeyWordSearch() {
            // setTimeout(() => {
            //     let transformText = "";
            //     const regex = /^[a-zA-Z]+$/;
            //     const arrKeyWord = this.hasTheWords.split(" ");
            //     arrKeyWord.forEach(x => {
            //             if (!regex.test(x) && !this.isContainsBlock(x) && x) {
            //                 transformText = transformText + `"${x}"` + " ";
            //             } else {
            //                 transformText = transformText + x + " ";
            //             }
            //         }
            //     );
            //     this.hasTheWords = transformText.replaceAll("  ", " ");
            // }, 200);
        },
        isContainsBlock(keyStr) {
            if (!keyStr || keyStr.length < 2) {
                return false;
            }
            if (keyStr[0] == "\"" && keyStr[keyStr.length - 1] == "\"") {
                return true;
            }
            return false;
        },

        ...mapActions(["getAllGeneral"]),
        updateDisabledButton() {
            LIST_EMAIL_ACTION.forEach((e) => {
                this.actionDisable[e] = false;
            });
            let disableField = [];
            if (this.isDraft) {
                disableField = [
                    EMAIL_ACTION.REPLY,
                    EMAIL_ACTION.REPLY_ALL,
                    EMAIL_ACTION.FORWARD,
                    EMAIL_ACTION.MARK_AS_UNREAD,
                    EMAIL_ACTION.MARK_AS_READ,
                    EMAIL_ACTION.MOVE,
                    EMAIL_ACTION.SPAM,
                    EMAIL_ACTION.UNSPAM
                ];
            }
            disableField.forEach((e) => {
                this.actionDisable[e] = true;
            });
            this.actionDisable[EMAIL_ACTION.MARK_AS_UNREAD] =
                this.actionDisable[EMAIL_ACTION.MARK_AS_UNREAD] ||
                !this.hasPermissionFolder(RIGHTS_FOLDER.WORKFLOW_ACTION);
            this.actionDisable[EMAIL_ACTION.MARK_AS_READ] =
                this.actionDisable[EMAIL_ACTION.MARK_AS_READ] ||
                !this.hasPermissionFolder(RIGHTS_FOLDER.WORKFLOW_ACTION);
            this.actionDisable[EMAIL_ACTION.MOVE] =
                this.actionDisable[EMAIL_ACTION.MOVE] ||
                !this.hasPermissionFolder(RIGHTS_FOLDER.WORKFLOW_ACTION);
            this.actionDisable[EMAIL_ACTION.SPAM] =
                this.actionDisable[EMAIL_ACTION.SPAM] ||
                !this.hasPermissionFolder(RIGHTS_FOLDER.WORKFLOW_ACTION);
            this.actionDisable[EMAIL_ACTION.DELETE] =
                this.actionDisable[EMAIL_ACTION.DELETE] ||
                !this.hasPermissionFolder(RIGHTS_FOLDER.WORKFLOW_ACTION);
            this.actionDisable[EMAIL_ACTION.MORE_ACTION] =
                this.actionDisable[EMAIL_ACTION.MORE_ACTION] ||
                !this.hasPermissionFolder(RIGHTS_FOLDER.WORKFLOW_ACTION);
            this.actionDisable[EMAIL_ACTION.ARCHIVE] =
                this.actionDisable[EMAIL_ACTION.ARCHIVE] || this.isArchive;
        },
        actiondAssginOrRemoveTag(tag, isAssgin) {
            this.handledAssginOrRemoveTag(tag, isAssgin);
            if (this.isNewWindow) {
                if (isAssgin) {
                    this.listTagAssgin.push(tag);
                } else {
                    this.listTagAssgin = this.listTagAssgin.filter(
                        (x) => x.id != tag.id
                    );
                }
            }
        },
        handleSearch(data) {
            this.visibleDialogSearchBox = false;
            const listQuery = [];
            const paramAgrs = {};
            if (data.tocc) {
                listQuery.push(`tocc:${data.tocc}`);
            }
            if (data.from) {
                listQuery.push(`from:${data.from}`);
            }
            if (data.fromOrToContact && data.fromOrToContact.length) {
                const _type = data.type;
                data.fromOrToContact.forEach((email, index) => {
                    if (index > 0) listQuery.push(`OR ${_type}:${email}`);
                    else listQuery.push(`${_type}:${email}`);
                });
                if (data.header) paramAgrs["header"] = data.header;
                if (data.sortBy) paramAgrs["sortBy"] = data.sortBy;
                if (data.tz) paramAgrs["tz"] = data.tz;
                if (data.locale) paramAgrs["locale"] = data.locale;
                if (data.recip) paramAgrs["recip"] = data.recip;
            }
            if (data.to?.length) {
                // listQuery.push(`to:${data.to}`);
                data.to.forEach(t => {
                    listQuery.push(`to:${t}`)
                })
            }

            if (data.subject) {
                listQuery.push(`subject:${data.subject}`);
            }
            if (data.hasTheWords) { /// tách các từ khi tìm kiếm
                data.hasTheWords?.trim().split(" ").forEach((str) => {
                    if (str.trim().length > 0) {
                        listQuery.push(`${str.trim()}`);
                    }
                });
            }
            if (data.doesNotHave) {
                data.doesNotHave?.trim().split(" ").forEach((str) => {
                    if (str.trim().length > 0) {
                        listQuery.push(`(not ${str.trim()})`);
                    }
                });
            }
            if (data.hasAttachment) {
                listQuery.push(`has:attachment`);
            }
            if (data.size && data.sizeAttach && data.sizeUnit) {
                listQuery.push(
                    `${data.size}:${data.sizeAttach}${data.sizeUnit}`
                );
            }
            if (data.time && data.date) {
                listQuery.push(`${data.time}:${data.date}`);
            }
            if (data.searchIn) {
                if (data.searchIn.absFolderPath === "/Flagged") {
                    listQuery.push(`is:flagged`);
                } else {
                    if (data.searchIn.absFolderPath == null) {
                        listQuery.push(`(tag:"${data.searchIn.name}")`);
                    } else {
                        listQuery.push(`in:"${data.searchIn.absFolderPath}"`);
                    }

                }
            }
            let query = listQuery.map((e) => _.escape(e)).join(" ");
            if (query === "") {
                query = `in:"/Inbox"`;
            }
            this.$store.commit("SET_LIST_QUERY", listQuery);
            this.$store.commit("SET_SHOW_SEARCH_BOX", true);
            this.$store.commit("SET_MODEL_SEARCH", data);
            this.$root.$emit("handleSearchBox", query, paramAgrs);
        },
        handldeSearchKeyword() {
            const _listQuery = [];
            const _listStrQuery = this.hasTheWords?.trim().split(" ");
            _listStrQuery.forEach((str) => {
                if (str.trim().length > 0) {
                    // _listQuery.push(`"${str.trim()}"`);
                    _listQuery.push(`${str.trim()}`); // sửa bỏ dấu nháy kép thừa
                }
            });
            this.$root.$emit("handleSearchKeyWord", _listQuery.join(" "));
            this.$store.commit("SET_LIST_QUERY_TEMP", _listQuery);
            this.hasTheWords = "";
        },
        initKeywordSearch() {
            this.getAllGeneral().then(() => {
                if (this.showSearchStringCurrent.content)
                    this.hasTheWords = this.mailInitialSearch.content;
            });
        },
        handleFocus(e) {
            e.preventDefault();
            this.$refs.searchbar.focus();
        },
        // handleNewMsg(e) {
        //     if (e.preventDefault) {
        //         e.preventDefault();
        //         this.$root.$emit("handleNavbarAction", EMAIL_ACTION.COMPOSE);
        //     } else {
        //         e.returnValue = false;
        //     }
        // },
        isFolder(id) {
            return this.activeNodeSidebar?.id === id;
        },
        oncloseNewWindow() {
            window.close();
        },
        handleNavbarAction(actionType) {
            if (this.isNewWindow) {
                this.$emit("handleNavbarAction", actionType);
            } else {
                this.$root.$emit("handleNavbarAction", actionType);
            }
        },
        showOriginal() {
            if (this.isNewWindow) {
                this.showMailOriginalNewWindow(this.dataMailNewWindow);
            } else {
                if (this.currentActiveEmail) {
                    this.showMailOriginalNewWindow(this.currentActiveEmail.data);
                }
            }
        },
        handleFocusOut() {
            this.visablePopoverMove = false;
            this.visablePopover = false;
        },
        handleFocusSearchInput() {
            this.$refs.searchbar.focus();
        },
        /**
         * Cập nhật giao diện hiện thời
         */
        checkMailPrefUpdate() {
            this.$root.$emit("handleUpdateView");
            // this.loadExternalMail();
        },

        async loadExternalMail() {
            const externalAcc = this.externalAccount.find(x => x.l == 2);
            const formData = {
                pop3: {
                    id: externalAcc.id
                }
            };
            ZimbraMailService.importDataRequest(formData).then(() => {
                this.$notify({
                    title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: this.$t("zimbra.zmMsg.dataSourceLoadSuccess", [externalAcc.name]),
                    type: "success"
                });
                this.getExternalMail();
            });
        },

        getExternalMail() {
            ZimbraMailService.getImportStatusRequest()
                .then((res) => {
                    const pop = this.getResponseBody(res)["GetImportStatusResponse"];
                    if (pop) {
                        const pop3 = Array.isArray(pop.pop3) ? pop.pop3 : [pop.pop3];
                        if (pop3.findIndex(x => x.isRunning) > -1) {
                            setTimeout(() => {
                                this.getExternalMail();
                            }, 5000);
                        }
                    }
                    const { context } = this.getResponseHeader(res);
                    const { created, modified } = context?.notify || {};

                    if ((created || modified) && this.hasHandledUpdateDataExternal) {
                        this.handleUpdateDataExternal(created, modified);
                        this.hasHandledUpdateDataExternal = false;
                    }
                })
                .catch(() => {
                });
        },
        handleUpdateDataExternal(created, modified) {
            if (modified) {
                const listFolderUpdate = Array.isArray(modified.folder) ? modified.folder : [modified.folder];
                listFolderUpdate.forEach(x => {
                    let folderUpdate = this.dataSidebarInBox[0].folder.find(e => e.id.toString() == x.id);
                    folderUpdate.n = x.n;
                    folderUpdate.u = x.u;
                    folderUpdate.s = x.s;
                    this.$root.$emit("updateItemToDataInbox", folderUpdate);
                });
                if (listFolderUpdate && listFolderUpdate.map(x => x.id).includes(this.activeNodeSidebar.id)) {
                    this.$root.$emit("reloadListMail");
                }
            }
        }
    },
    destroyed() {
        this.$root.$off("handleClearSearchHighlights", this.handleFocusSearchInput);
    }
};
</script>
<style lang="scss">
.head-main {
    & .left {
        &.visible {
            visibility: hidden !important;
        }
    }
}

.main-pri {
    height: unset !important;
}
</style>
