
import {
    DATASOURCE_FREE_BUSY,
    FLAG_FOLDER,
    CALENDAR_VIEW_MODE,
    CALENDAR_VIEW_MODE_ZIMBRA,
    CAL_ROLE_AT,
    ITEM_ACTION,
    FOLDER_ACTION,
    FOLDER_RENAME,
    CAL_ALARM_TYPE,
    CALENDAR_FREQ_TYPE,
    FOLDER_VIEW,
    ROOT_CALENDAR_FOLDER_ID,
    CAL_RECUR_DAILY_TYPE,
    DATASOURCE_TIME_DAYS,
    CAL_RECUR_WEEKLY_TYPE,
    CAL_RECUR_MONTHLY_TYPE,
    DATASOURCE_MONTHS,
    CAL_RECUR_YEARLY_TYPE,
    RECUR_END_INTERVAL_TYPE,
    CAL_OPEN_RECURRING_ITEM,
    CAL_OPERATOR_REPLY_INV,
    DEFAULT_FORM_SEARCH_CAL,
    CALENDAR_LINK_TYPE,
    CALENDAR_RESOURE_TYPE,
    CAL_PARTICIPATION_STATUS,
    EMAIL_FLAG,
    APPOINTMENT_FORM_TYPE,
    STATUS_REQUEST,
    DATASOURCE_FREE_BUSY_FULL,
    FOLDER_DEFAULT
} from "@/utils/constants.js";
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import CommonUtils from "@/utils/common-utils";
import ZmMimeTable from "@/utils/zimbra-lib/ZmMimeTable";
import CompileService from "@/services/CompileService.js";
import EmailUtils from "@/utils/email-utils";
export default {
    name: "CalendarUtils",
    mixins: [CommonUtils, EmailUtils],
    data() {
        return {
            // form dữ liệu tìm kiêm bên trái
            formMenuLeft: {
                listCalFolderIds: [],
                tagName: ""
            }
        };
    },
    computed: {
        ...mapGetters([
            "treeCalendarFolder",
            "preference",
            "emailAddress",
            "timeZoneCurrent",
        ]),
        datasourceCalendarFolder() {
            return this.getListCalendarFolder(this.treeCalendarFolder);
        }
    },
    methods: {
        getColorFreeBusy(fb) {
            const _type = DATASOURCE_FREE_BUSY.find(e => e.value == fb);
            return _type ? _type.color : "";
        },
        getHexaToRgba(hex, opacityRate) {
            const rgb = this.hexToRgb(hex)
            return `rgba(${rgb.toString()},${opacityRate})`
        },
        getNameFreeBusy(fb) {
            const _type = DATASOURCE_FREE_BUSY.find(e => e.value == fb);
            return _type ? _type.label : "";
        },
        getStatusAppointmentName(apptData) {
            const _ptst = apptData.ptst
            let text = "";
            // nếu là cuộc hẹn của mình - chưa có người tham gia => trạng thái là rỗng
            if(this.isOwnAppt(apptData) && !apptData.otherAtt) {
                return text;
            }
            switch(_ptst) {
                case CAL_PARTICIPATION_STATUS.ACCEPT:
                    text = this.$t("zimbra.zhMsg.apptPtstAC");
                    break;
                case CAL_PARTICIPATION_STATUS.TENTATIVE:
                    text = this.$t("zimbra.zhMsg.apptPtstTE");
                    break;
                case CAL_PARTICIPATION_STATUS.DECLINED:
                    text = this.$t("zimbra.zhMsg.apptPtstDE");
                    break;
                case CAL_PARTICIPATION_STATUS.NEEDS_ACTION:
                    // text = this.$t("zimbra.zmMsg.needsAction");
                    text = this.$t("zimbra.zhMsg.apptPtstNEW");
                    break;
                default:
                    break
            }
            return text
        },
        getListCalendarFolder(listFolder) {
            let res = [];
            if (listFolder?.length > 0) {
                listFolder.forEach(folder => {
                    if (folder.perm != "r") {
                        res.push({ ...folder, folder: [] });
                        res = _.concat(res, this.getListCalendarFolder(folder.folder));
                    }
                });
            }
            return res;
        },
        getNameCalendarFolderById(_id) {
            // nếu là thùng rác thì trả về luôn loại tên thùng rác
            if(_id == FOLDER_DEFAULT.TRASH) {
                return this.$t("zimbra.zhMsg.FOLDER_LABEL_3");
            }
            const res = this.datasourceCalendarFolder?.find(x => x.id == _id)
            return res?.name;
        },
        getFolderSelect(id) {
            return this.datasourceCalendarFolder.find(e => e.id === id);
        },
        getIcon() {
            return "calendar-icon"; // tạm thời fix cứng
        },
        getDefautTime(isStartTime, startTime, duration) {
            const _current = moment(startTime ? startTime : new Date());
            const _minute = _current.minutes();
            if (!startTime) { // Nếu không có giờ thủ công thì làm tròn
                if (_minute <= 30) {
                    _current.set('minute', 30);
                } else {
                    _current.set('minute', 0);
                    _current.set('hour', _current.hours() + 1);
                }
            }
            if (isStartTime) {
                return _current.startOf('minute').format("yyyyMMDDTHHmmss");
            } else {
                if (!duration) { // Nếu không co duration thủ công thì cộng theo mặc định
                    duration = this.preference.zimbraPrefCalendarDefaultApptDuration === "60m" || this.preference.zimbraPrefCalendarDefaultApptDuration == 60 ? 60 * 60 : this.preference.zimbraPrefCalendarDefaultApptDuration;
                    _current.add(duration, 'seconds');
                } else {
                    _current.add(duration, 'seconds');
                }
                return _current.startOf('minute').format("yyyyMMDDTHHmmss");
            }
        },

        /**
        * Hàm tìm kiếm event
        */
        async searchListEvent() {
            const formData = this.makeFormSearch();
            await ZimbraMailService.searchRequest(formData)
                .then((res) => {
                    this.lstEvent = [];
                    let lstEvent = [];
                    let searchResponse = this.getResponseBody(res)["SearchResponse"]["appt"];

                    // Kiểm tra thuộc tính là array không
                    if (searchResponse && !Array.isArray(searchResponse)) {
                        searchResponse = [].concat(searchResponse)
                    }

                    // Xử lý envet lặp
                    lstEvent = lstEvent.concat(this.convertItemLoop(searchResponse));

                    // Xử lý envent thường
                    searchResponse = searchResponse?.filter(x => !x.recur);
                    if (searchResponse && searchResponse.length > 0) {
                        lstEvent = lstEvent.concat(searchResponse.map((itemEvent) =>
                            this.convertItem(itemEvent)
                        ));
                    }
                    this.lstEvent = lstEvent;
                    this.$store.commit("SET_LST_EVENT_SEARCH", lstEvent);
                })
                .catch((err) => {
                    const { detail } = this.getResponseBody(err.response)[
                        "soap:Fault"
                    ];
                    if (detail.Error.Code) {
                        this.$store.commit("SET_LST_EVENT_SEARCH", undefined);
                        this.$store.commit(
                            "SET_SEARCH_CAL_ERROR_MESSAGE",
                            `zimbra.error.${detail.Error.Code}`
                        );
                    }
                });
        },

        /**
        * Hàm tìm kiếm event để thực hiện cảnh báo
        */
        async searchListEventToWarning(listCalendarId) {

            const queryCal = this.makeCalFolderQuery(listCalendarId);

            const formData = {
                offset: DEFAULT_FORM_SEARCH_CAL.OFFSET,
                sortBy: DEFAULT_FORM_SEARCH_CAL.SORT_BY,
                types: DEFAULT_FORM_SEARCH_CAL.TYPE,
                limit: DEFAULT_FORM_SEARCH_CAL.LIMIT,
                locale: { content: this.localeCurrent.content },
                query: queryCal,
                calExpandInstStart: moment(moment(Date()).add(-7, "days").toDate()).startOf("day").toDate().getTime().toString(),
                calExpandInstEnd: moment(new Date()).endOf("day").toDate().getTime().toString(),
            };

            await ZimbraMailService.searchRequest(formData)
                .then((res) => {
                    let lstEvent = [];
                    let searchResponse = this.getResponseBody(res)["SearchResponse"]["appt"];

                    // Kiểm tra thuộc tính là array không
                    if (searchResponse && !Array.isArray(searchResponse)) {
                        searchResponse = [].concat(searchResponse)
                    }

                    // Xử lý envet lặp
                    lstEvent = lstEvent.concat(this.convertItemLoop(searchResponse));

                    // Xử lý envent thường
                    searchResponse = searchResponse?.filter(x => !x.recur);
                    if (searchResponse && searchResponse.length > 0) {
                        lstEvent = lstEvent.concat(searchResponse.map((itemEvent) =>
                            this.convertItem(itemEvent)
                        ));
                    }

                    // Bỏ những cuộc hẹn nằm trong lịch người ngoài chia sẻ vào
                    lstEvent = lstEvent.filter(event => !this.isCalendarFolderShare(event))

                    this.$store.commit("SET_LIST_APPOINMENT", lstEvent);
                })
                .catch((err) => {
                    console.log("lỗi xảy ra", err);
                });
        },

        /**
         * Hàm convert item từ api thành item even cal
         */
        convertItem(itemEvent) {
            // Trường hợp event là cả ngày
            if (itemEvent.allDay == 1) {
                const dateAllDay = moment(itemEvent.inst.s)
                    .startOf("day")
                    .toDate();
                return {
                    ...itemEvent,
                    title: itemEvent.name,
                    start: dateAllDay,
                    end: dateAllDay,
                    allDay: itemEvent.allDay == 1,
                };
            }
            // Trường hợp còn lại
            return {
                ...itemEvent,
                title: itemEvent.name,
                start: itemEvent.start ?? itemEvent.inst.ridZ,
                end: new Date(itemEvent.inst.s + itemEvent.dur).toISOString(),
                allDay: itemEvent.allDay == 1,
            };
        },

        /**
         * Hàm convert các event của cuộc hẹn lặp
         */
        convertItemLoop(lstEvent) {
            // Lọc ra các cuộc hẹn lặp
            let events = [];
            if (lstEvent && lstEvent.length > 0) {
                events = lstEvent.filter(x => x.recur)
            }

            // Xử lý convert cuộc hẹn lặp trong events
            const lstExpandEvent = [];
            events.forEach(evt => {
                // Nếu evt.inst là dạng object ==> chuyển thành array để for
                if(!Array.isArray(evt.inst)){
                    evt.inst = [evt.inst]
                }
                // Biến đánh dấu số thự tự cuộc hẹn
                let _comp = 0;
                evt.inst.forEach(evtInts => {
                    // Trường hợp cuộc hẹn sửa đơn thì bổ sung các thuộc tính riêng
                    if(evtInts.recur == false || evtInts.recur == 0){
                        const eventSpecial = { ...evt,...evtInts, inst: {s: evtInts.s, ridZ: evtInts.ridZ}, reCurName: evt.name, comp: _comp };
                        // thay đổi giá trị start với trường hợp lặp
                        if(evtInts.s) {
                            eventSpecial.start = moment(new Date(eventSpecial.s)).toISOString()
                        }
                        lstExpandEvent.push(this.convertItem(eventSpecial))
                    } else {
                        lstExpandEvent.push(this.convertItem({ ...evt, inst: evtInts, comp: _comp }))
                    }
                    _comp++;
                })
            });
            return lstExpandEvent;
        },

        /**
         * Hàm chuyển dữ liệu date ==> date cho lib calendar
         * @param {*} date
         */
        getDateForCalendar(date) {
            return moment(date).startOf('day').add(1, 'days').toDate();
        },

        /**
         * Hàm chuyển dữ liệu date ==> date cho lib calendar
         * @param {*} date
         */
        convertTimeStampToDateStr(dateTime, _format) {
            return moment(new Date(dateTime)).format(_format ? _format : "MM/DD/YYYY");
        },

        /**
         * Hàm duyệt cây để lấy ra những bản ghi đã check
         */
        searchTreeToGetCheck(node, calFolderCheckedIds) {
            if (!node) {
                return;
            }
            if (node.f && node.f.includes(FLAG_FOLDER.CHECKED)) {
                calFolderCheckedIds.push(node.id)
            }
            if (!node.folder) {
                return;
            }
            for (let i = 0; i < node.folder.length; i++) {
                this.searchTreeToGetCheck(node.folder[i], calFolderCheckedIds)
            }
        },

        /**
         * Hàm duyệt cây để lấy ra những bản ghi con
         */
        searchTreeIds(node, calFolderIds) {
            if (!node || node.isLink) {
                return;
            }
            calFolderIds.push(node.id)
            if (!node.folder) {
                return;
            }
            for (let i = 0; i < node.folder.length; i++) {
                this.searchTreeIds(node.folder[i], calFolderIds)
            }
        },

        /**
         * Hàm tìm kiếm node trên tree theo id
         */
        searchNodeById(node, nodeId) {
            if (!node) {
                return;
            }
            let cal = null;
            if (node.id == nodeId) {
                return node;
            } else if(node.folder) {
                for (let i = 0; i < node.folder.length; i++) {
                    cal = this.searchNodeById(node.folder[i], nodeId);
                    if (cal) {
                        break;
                    }
                }
            }
            return cal;
        },

        /**
         * Hàm tìm kiếm node chia sẻ trên tree theo id
         */
        searchNodeShareById(node, nodeId) {
            if (!node) {
                return;
            }
            let cal = null;
            if (node.rid == nodeId) {
                return node;
            } else if(node.folder) {
                for (let i = 0; i < node.folder.length; i++) {
                    cal = this.searchNodeShareById(node.folder[i], nodeId);
                    if (cal) {
                        break;
                    }
                }
            }
            return cal;
        },

        /**
         * Hàm lấy cấu hình lịch phục vụ tìm kiếm
         */
        async getCalFolder() {
            const formData = {
                folder: `<view>appointment</view><depth>-1</depth><tr>1</tr><visible>1</visible><needGranteeName>1</needGranteeName>`,
            };
            const searchRequest = await ZimbraMailService.getFolderRequest(formData);
            const searchActionResponse = this.getResponseBody(searchRequest)["GetFolderResponse"]?.folder;
            const _folder = this.convertCalFolder(searchActionResponse);
            return _folder[0].folder;
        },

        /**
         * convert cấu trúc tree folder
         * @param {*} folder
         * @returns
         */
        convertCalFolder(folder) {
            if (!folder) {
                return [];
            }
            folder = Array.isArray(folder) ? folder : [folder];
            for (let index = 0; index < folder.length; index++) {
                const item = folder[index];
                let link = [];
                if (item.link) {
                    link = Array.isArray(item.link) ? item.link : [item.link];
                    this.tickLinkFolder(item.link)
                }
                if (item.folder) {
                    item.folder = Array.isArray(item.folder) ? item.folder : [item.folder];
                } else {
                    item.folder = [];
                }
                // convert tên folder
                item.name = FOLDER_RENAME.includes(item.name)
                    ? i18n.t(`zimbra.systemFolder.folder${item.name}`)
                    : item.name;
                item.folder = item.folder.concat(link);
                item.folder = this.convertCalFolder(item.folder);
            }
            return folder;
        },

        /**
         * hàm tạo phần query của lịch đã chọn trên màn hình
         * @param {*} lstFolderCheckIds
         */
        makeCalFolderQuery(lstFolderCheckIds) {
            if (!lstFolderCheckIds || lstFolderCheckIds.length == 0) {
                return "";
            }
            let query = lstFolderCheckIds.reduce((reduceQuery, id) => {
                return reduceQuery + `OR inid:"${id}" `;
            }, "");
            return "(" + query.substring(3) + ")";
        },

        /**
         * hàm tạo query tag name
         * @param {*} lstFolderCheckIds
         */
        makeCalTagNameQuery(tagName) {
            if (!tagName) {
                return "";
            }
            return ` (tag:"${tagName}") `;
        },

        /**
         * Hàm lấy dữ liệu cuộc hẹn qua invId
         * @param {*} invId
         */
        async getAppointmentById(invId) {
            const formData = {
                id: `${invId}`
            };
            const msgRequest = await ZimbraMailService.getMsgRequest(formData);
            const msgResponse = this.getResponseBody(msgRequest)[
                "GetMsgResponse"
            ];
            return msgResponse;
        },

        /**
         * Hàm cập nhật lại sự kiện
         * @param {*} formData
         */
        async modifyAppointmentRequest(formData) {
            const msgRequest = await ZimbraMailService.modifyAppointmentRequest(formData);
            const msgResponse = this.getResponseBody(msgRequest)[
                "ModifyAppointmentResponse"
            ];
            return msgResponse;
        },

        /**
         * Hàm convert date ==> chuỗi string date để gửi lên cập nhậ
         * @param {*} date
         */
        convertDateToDateZimbra(date, _format) {
            if (!date) {
                return "";
            }
            return moment(date).format(_format ?? "yyyyMMDDTHHmmss");
        },

        /**
         * Hàm convert date ==> chuỗi string date để gửi lên cập nhậ
         * @param {*} dateStr
         */
        convertStringToDate(dateStr, _format) {
            if (!dateStr) {
                return "";
            }
            return moment(dateStr).format(_format ?? "DD-MM-YYYY");
        },

        /**
         * hàm lấy view mode từ view zimbra trả về
         * @param {*} innitViewType
         */
        getViewModeFromZimbra(innitViewType) {
            let viewMode = "";
            switch (innitViewType) {
                case CALENDAR_VIEW_MODE_ZIMBRA.DAY:
                    viewMode = CALENDAR_VIEW_MODE.DAY;
                    break;
                case CALENDAR_VIEW_MODE_ZIMBRA.WEEK_WORK:
                    viewMode = CALENDAR_VIEW_MODE.WEEK_WORK;
                    break;
                case CALENDAR_VIEW_MODE_ZIMBRA.WEEK:
                    viewMode = CALENDAR_VIEW_MODE.WEEK;
                    break;
                case CALENDAR_VIEW_MODE_ZIMBRA.MONTH:
                    viewMode = CALENDAR_VIEW_MODE.MONTH;
                    break;
                case CALENDAR_VIEW_MODE_ZIMBRA.LIST:
                    viewMode = CALENDAR_VIEW_MODE.LIST;
                    break;
                default:
                    viewMode = CALENDAR_VIEW_MODE.DAY;
            }
            return viewMode;
        },

        /**
         * Hàm hủy cuộc hẹn (chuyển vào thùng rác)
         * @param {*} apptId
         * @param {*} _inst
         */
        async cancelAppointmentRequest(apptId, _inst) {
            this.$confirm(
                this.$t("zimbra.zmMsg.confirmCancelAppt"),
                this.$t("zimbra.zmMsg.confirmDeleteApptTitle"),
                {
                    confirmButtonText: this.$t("zimbra.zhMsg.yes"),
                    cancelButtonText: this.$t("zimbra.zhMsg.no"),
                    type: "error",
                }
            ).then(async () => {
                await ZimbraMailService.cancelAppointmentRequest({
                    id: apptId,
                    comp: "0",
                    inst: _inst
                });
                this.$notify({
                    title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: this.$t("zimbra.zmMsg.actionDelete", { 0: 1, 1: this.$t("zimbra.zhMsg.ALT_MSG_STATUS_APPT") }),
                    type: "success",
                });
                // Tìm kiếm lại sau khi xóa
                this.$root.$emit("onSearchAppointmentRequest");
            }).catch(() => { });
        },

        /**
         * Hàm hủy cuộc hẹn định kỳ - trường hợp chuỗi
         * @param {*} formData
         */
         async cancelAppointmentInstanceRequest(apptData, _inst) {
            this.$confirm(
                this.$t("zimbra.zmMsg.confirmCancelApptInst", [apptData.name]),
                this.$t("zimbra.zmMsg.confirmDeleteApptTitle"),
                {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    type: "error",
                }
            ).then(async () => {
                await ZimbraMailService.cancelAppointmentRequest({
                    id: apptData.invId,
                    s: apptData.inst.s.toString(),
                    inst: _inst,
                    comp: "0"
                });
                this.$notify({
                    title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: this.$t("zimbra.zmMsg.actionDelete", { 0: 1, 1: this.$t("zimbra.zhMsg.ALT_MSG_STATUS_APPT") }),
                    type: "success",
                });
                // Tìm kiếm lại sau khi xóa
                this.$root.$emit("onSearchAppointmentRequest");
            }).catch(() => { });
        },
        /**
         * Hàm hủy cuộc hẹn định kỳ - trường hợp chuỗi - có người tham dự
         * @param {*} formData
         */
        async cancelAppointmentInstanceReplyRequest(apptData, _inst) {
            await ZimbraMailService.cancelAppointmentRequest({
                id: apptData.invId,
                s: apptData.inst.s.toString(),
                inst: _inst,
                comp: apptData.comp,
            });
            this.$notify({
                title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                message: this.$t("zimbra.zmMsg.actionDelete", { 0: 1, 1: this.$t("zimbra.zhMsg.ALT_MSG_STATUS_APPT") }),
                type: "success",
            });
            // Tìm kiếm lại sau khi xóa
            this.$root.$emit("onSearchAppointmentRequest");
        },

        /**
         * Hàm hủy cuộc hẹn định kỳ - trường series
         * @param {*} formData
         */
        async cancelAppointmentSeriesRequest(apptId) {
            await ZimbraMailService.cancelAppointmentRequest({
                id: apptId,
                comp: "0"
            });
            this.$notify({
                title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                message: this.$t("zimbra.zmMsg.actionDelete", { 0: 1, 1: this.$t("zimbra.zhMsg.ALT_MSG_STATUS_APPT") }),
                type: "success",
            });
            // Tìm kiếm lại sau khi xóa
            this.$root.$emit("onSearchAppointmentRequest");
        },

        /**
         * Khởi tạo giá trị tùy chọn lặp lại
         * @param {*} recur
         */
        initialCustomRepeat(recur, startDate) {
            const _today = moment(new Date());
            const customRepeat = {};
            const { rule } = recur.add;
            customRepeat.freq = rule.freq;
            switch(customRepeat.freq) {
                case CALENDAR_FREQ_TYPE.DAILY:
                    // Hàng ngày
                    if (rule.interval?.ival) {
                        if (rule.interval.ival === 1) {
                            customRepeat.daily = CAL_RECUR_DAILY_TYPE.EVERY_DAY;
                            customRepeat.recurDailyEveryNumDays = [2];
                        } else {
                            customRepeat.daily = CAL_RECUR_DAILY_TYPE.EVERY_NUM_DAYS;
                            customRepeat.recurDailyEveryNumDays = [rule.interval.ival];
                        }
                    } else {
                        customRepeat.daily = CAL_RECUR_DAILY_TYPE.EVERY_WEEKDAY
                    }
                    break;
                case CALENDAR_FREQ_TYPE.WEEKLY: {
                    // Hàng tuần
                    const { interval, byday } = rule;
                    if (byday) {
                        byday.wkday = Array.isArray(byday.wkday) ? byday.wkday : (byday.wkday ? [byday.wkday] : []);
                        if (byday.wkday.length > 1 || interval.ival > 1) {
                            customRepeat.weekly = CAL_RECUR_WEEKLY_TYPE.EVERY_NUM_WEEKS_DATE;
                            customRepeat.recurWeeklyEveryWeekday = [byday.wkday[byday.wkday.length - 1].day];
                            customRepeat.recurWeeklyEveryNumWeeksDate = [
                                interval.ival,
                                byday.wkday.map(e => e.day)
                            ];
                        } else if (byday.wkday.length === 1) {
                            customRepeat.weekly = CAL_RECUR_WEEKLY_TYPE.EVERY_WEEKDAY;
                            customRepeat.recurWeeklyEveryWeekday = [byday.wkday[0].day];
                            customRepeat.recurWeeklyEveryNumWeeksDate = [2, []];
                        }
                    } else {
                        customRepeat.weekly = CAL_RECUR_WEEKLY_TYPE.EVERY_WEEKDAY;
                        customRepeat.recurWeeklyEveryWeekday = ["MO"];
                        customRepeat.recurWeeklyEveryNumWeeksDate = [2, []];
                    }
                    break;
                }
                case CALENDAR_FREQ_TYPE.MONTHLY: {
                    const { bymonthday, bysetpos, byday, interval } = rule;
                    if (bymonthday) {
                        customRepeat.monthly = CAL_RECUR_MONTHLY_TYPE.EVERY_NUM_MONTHS_DATE;
                        customRepeat.recurMonthlyEveryNumMonthsDate = [bymonthday.modaylist, interval.ival];
                        customRepeat.recurMonthlyEveryNumMonthsWeekDays = [1, "MO", 1];
                    } else {
                        customRepeat.monthly = CAL_RECUR_MONTHLY_TYPE.EVERY_NUM_MONTHS_NUMDAY;
                        customRepeat.recurMonthlyEveryNumMonthsDate = [moment(startDate).day(), 1];
                        let wkday = null;
                        const numberDay = byday?.wkday?.length;
                        if (numberDay === 7) {
                            // ngày
                            wkday = -1;
                        } else if (numberDay === 5) {
                            // ngày trong tuần
                            wkday = 1;
                        } else if (numberDay === 2) {
                            // ngày cuối tuần
                            wkday = 0;
                        } else if (numberDay === 1) {
                            wkday = byday.wkday[0].day;
                        }
                        customRepeat.recurMonthlyEveryNumMonthsWeekDays = [bysetpos?.poslist, wkday, interval.ival];
                    }
                    break;
                }
                case CALENDAR_FREQ_TYPE.YEARLY: {
                    const { bymonth, bymonthday, bysetpos, byday } = rule;
                    if (bymonthday) {
                        customRepeat.yearly = CAL_RECUR_YEARLY_TYPE.EVERY_DATE;
                        customRepeat.recurYearlyEveryDate = [bymonth.molist, bymonthday.modaylist];
                        customRepeat.recurYearlyEveryMonthWeekDays = [1, "SU", _today.month() + 1];
                    } else {
                        customRepeat.yearly = CAL_RECUR_YEARLY_TYPE.EVERY_MONTH_WEEK_DAYS;
                        customRepeat.recurYearlyEveryDate = [_today.month() + 1, 1];
                        let wkday = null;
                        const numberDay = byday?.wkday?.length;
                        if (numberDay === 7) {
                            // ngày
                            wkday = -1;
                        } else if (numberDay === 5) {
                            // ngày trong tuần
                            wkday = 1;
                        } else if (numberDay === 2) {
                            // ngày cuối tuần
                            wkday = 0;
                        } else if (numberDay === 1) {
                            wkday = byday.wkday[0].day;
                        }
                        customRepeat.recurYearlyEveryMonthWeekDays = [bysetpos?.poslist, wkday, bymonth?.molist];
                    }
                    break;
                }
            }
            // kết thúc lặp
            const { count, until } = rule;
            if (count) {
                customRepeat.recurEndType = RECUR_END_INTERVAL_TYPE.NUMBER;
                customRepeat.recurEndNumber = [count.num];
                customRepeat.recurEndByDate = [startDate];
            } else if (until) {
                customRepeat.recurEndType = RECUR_END_INTERVAL_TYPE.DATE;
                customRepeat.recurEndNumber = [1];
                customRepeat.recurEndByDate = [until.d];
            } else {
                customRepeat.recurEndType = RECUR_END_INTERVAL_TYPE.NONE;
                customRepeat.recurEndNumber = [1];
                customRepeat.recurEndByDate = [startDate];
            }
            return customRepeat;
        },
        /**
         * Trạng thái người tham dự theo nhóm
         * @param {*} at
         */
        computedAttendGroupStatus(at) {
            const res = [];
            if (at) {
                for (let index = 0; index < at.length; index++) {
                    const _idx = res.findIndex(e => e.ptst === at[index].ptst);
                    if (_idx >= 0) {
                        res[_idx].at.push(at[index].a);
                    } else {
                        res.push({
                            ptst: at[index].ptst,
                            at: [at[index].a]
                        });
                    }
                }
            }
            return res.map(e => ({
                ...e,
                atStr: e.at.join(", "),
                ptstText: this.getParticipationStatusText(e.ptst, false),
                count: e.at.length
            }));
        },
        /**
         * Khởi tạo dữ liệu cho màn hình sửa cuộc hẹn/xem cuộc hẹn trong mail
         * @param {*} msg
         * @returns
         */
        initialDataForEdit(msg, formType, apptDataInstance) {
            if (!msg) {
                return {};
            }
            const { inv, mp, id } = msg;
            if (!inv) {
                return {};
            }
            const comp = Array.isArray(inv.comp) ? inv.comp[0] : inv.comp;
            let { reply } = Array.isArray(inv.replies) ? inv.replies[0] : inv.replies || {};
            let { at, s, e, alarm, recur, desc, descHtml, or, neverSent, allDay } = comp;
            if (at) {
                // người tham dự
                at = Array.isArray(at) ? at : [at];
                const atReq = at.filter(e => e.role === CAL_ROLE_AT.REQUIRED)
                    .map(e => ({ ...e, text: this.getTextAddressSelector(e) }));
                const atOpt = at.filter(e => e.role === CAL_ROLE_AT.OPTIONAL)
                    .map(e => ({ ...e, text: this.getTextAddressSelector(e) }));
                comp.to = this.convertResource(atReq, CALENDAR_RESOURE_TYPE.REQUIRED_ATTENDEE);
                // Nếu chuyển tiếp + là chủ cuộc hẹn ==> bỏ người tham dự là Chính mình
                if (formType == APPOINTMENT_FORM_TYPE.FORWARD && this.emailAddress == or.a) {
                    comp.to = comp.to.filter(e => e.a != this.emailAddress);
                }
                comp.optional = this.convertResource(atOpt, CALENDAR_RESOURE_TYPE.OPTIONAL_ATTENDEE);
                comp.at = at.map(e => ({ ...e, text: this.getTextAddressSelector(e) }));
                comp.atGroup = this.computedAttendGroupStatus(at);
            }
            // Thiết định thời gian cuộc hẹn
            const format = "yyyyMMDDTHHmmss";
            if(apptDataInstance && apptDataInstance.recur != 1) { // Trường hợp cuộc hẹn cụ thể trong series
                if (allDay) {
                    comp.time = [moment(apptDataInstance.inst.ridZ, "YYYYMMDD").format(format), moment(apptDataInstance.inst.ridZ, "YYYYMMDD").format(format)];
                    comp.timeOrigin = [moment(apptDataInstance.inst.ridZ, "YYYYMMDD"), moment(apptDataInstance.inst.ridZ, "YYYYMMDD")];
                } else {
                    comp.time = [moment(apptDataInstance.inst.ridZ).format(format), moment(apptDataInstance.inst.ridZ).add(apptDataInstance.dur, 'milliseconds').format(format)];
                    comp.timeOrigin = [moment(apptDataInstance.inst.ridZ), moment(apptDataInstance.inst.ridZ).add(apptDataInstance.dur, 'milliseconds')];
                }
            } else {
                if (allDay) {
                    comp.time = [moment(s.d, "YYYYMMDD").format(format), moment(e.d, "YYYYMMDD").format(format)];
                    comp.timeOrigin = [moment(s.d, "YYYYMMDD"), moment(e.d, "YYYYMMDD")];
                } else {
                    if (s.d && e.d) {
                        comp.time = [s.d, e.d];
                    } else {
                        comp.time = [moment(s.u).format(format), moment(e.u).format(format)];
                    }
                    comp.timeOrigin = [moment(s.u), moment(e.u)];
                }
            }
            comp.timezoneStart = s.tz;
            comp.timezoneEnd = e.tz;
            comp.timePrevious = comp.time;
            const _start = moment(comp.time[0]).toDate().getTime();
            const _end = moment(comp.time[1]).toDate().getTime();
            if (!comp.inst) {
                comp.inst = {
                    s: _start
                }
            }
            if (!comp.dur) {
                comp.dur = _end - _start;
            }
            // Lặp lại
            if (recur) {
                comp.freq = recur?.add?.rule?.freq ? CALENDAR_FREQ_TYPE.CUSTOM : CALENDAR_FREQ_TYPE.NONE;
                comp.customRepeat = this.initialCustomRepeat(recur, comp.time[0]);
            } else {
                comp.freq = CALENDAR_FREQ_TYPE.NONE;
            }

            // Trình nhắc
            comp.alarm = -1;
            if (alarm) {
                alarm = Array.isArray(alarm) ? alarm : [alarm];
                const alarmDisplay = alarm.find(e => e.action === CAL_ALARM_TYPE.DISPLAY);
                const alarmEmail = alarm.find(e => e.action === CAL_ALARM_TYPE.EMAIL);
                if (alarmDisplay) {
                    comp.alarm = alarmDisplay.trigger.rel.m || alarmDisplay.trigger.rel.s; // Xử lý đặc biệt trường hợp thông báo tại thời điểm diễn ra
                }
                if (alarmEmail) {
                    comp.calendarReminderEmail = true;
                }
            }

            // Cuộc hẹn mật
            comp.class = comp.class == "PRI";

            // File đính kèm
            const _mp = mp?.mp || [];
            if(Array.isArray(_mp))
            {
                comp.listAttachment = _mp.filter(e => e.cd === "attachment")
                .map(e => ({
                    ...e,
                    selected: true,
                    name: this.getFileNameWithSize(e),
                    href: this.createUrlDownloadFile(id, e.part)
                }));
            }

            comp.l = msg.l;
            comp.id = id;
            comp.invId = `${id}-${inv.id}`;
            comp.descHtml = descHtml;
            comp.desc = desc;
            if (formType == APPOINTMENT_FORM_TYPE.FORWARD || formType == APPOINTMENT_FORM_TYPE.CLONE) {
                comp.or = {
                    a: this.emailAddress
                }
            } else {
                comp.or = or;
            }
            comp.neverSent = neverSent;
            comp.contentType = descHtml ? ZmMimeTable.HTML : ZmMimeTable.TEXT;
            if (or) {
                comp.replyTo = [{
                    ...or,
                    text: this.getDisplayNameAddress(or)
                }];
            }
            if (!comp.ptst && reply) {
                reply = Array.isArray(reply) ? reply : [reply];
                const userReply = reply.find(e => e.at === this.emailAddress)
                comp.ptst = userReply ? userReply.ptst : "";
            }
            comp.ptstText = this.getParticipationStatusText(comp.ptst),
            comp.fbText = this.getFreeBusyText(comp.fb),
            comp.recurClass = comp.recur ? "ic-reload-blue" : (comp.recur === false ? "ic-reload-red" : "")
            return comp;
        },
        convertResource(resources, resouceType) {
            return resources.map(e => ({
                ...e,
                displayName: e.text,
                label: e.text,
                email: e.a,
                resouceType: resouceType,
                isEditable: true,
                replyInv: this.convertPtst2ReplyInv(e.ptst),
            }));
        },
        convertPtst2ReplyInv(ptst) {
            let replyInv = CAL_OPERATOR_REPLY_INV.NEEDS_ACTION;
            switch (ptst) {
                case CAL_PARTICIPATION_STATUS.NEEDS_ACTION:
                    replyInv = CAL_OPERATOR_REPLY_INV.NEEDS_ACTION;
                    break;
                case CAL_PARTICIPATION_STATUS.TENTATIVE:
                    replyInv = CAL_OPERATOR_REPLY_INV.TENTATIVE;
                    break;
                case CAL_PARTICIPATION_STATUS.ACCEPT:
                    replyInv = CAL_OPERATOR_REPLY_INV.ACCEPT;
                    break;
                case CAL_PARTICIPATION_STATUS.DECLINED:
                    replyInv = CAL_OPERATOR_REPLY_INV.DECLINE;
                    break;
            }
            return replyInv;
        },
        /**
         * hàm chuyển chuyển cuộc hẹn
         * @param {*} formMove
         */
        async moveAppointment(idItem, idFolder, nameFolder) {
            await ZimbraMailService.itemActionRequest({
                id: idItem,
                l: idFolder.toString(),
                op: ITEM_ACTION.MOVE
            }
            );
            // Thông báo thành công
            this.$notify({
                title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                message: this.$t("zimbra.zmMsg.actionMove", [1, this.$t("zimbra.zhMsg.ALT_MSG_STATUS_APPT"), `"${nameFolder}"`]),
                type: "success",
            });
            // Tìm kiếm lại sau khi chuyển
            this.$root.$emit("onSearchAppointmentRequest");
        },

        formatter(isHtml, label, value, extraValue) {
            return isHtml
                ? `<tr><th align='left'>${label}</th><td>${value} ${extraValue}</td></tr>`
                : `${label} ${value} ${extraValue}`;
        },
        isMultiDay(startTime, endTime) {
            const duration = moment.duration(endTime.diff(startTime));
            return duration.asDays() > 1;
        },
        formatDateFull(allDay, date) {
            if (allDay) {
                // Thứ Ba, 16 Tháng Mười Một, 2021, Cả ngày
                const allDayLabel = allDay ? this.$t("zimbra.zhMsg.allDay") : "";
                const time = date.format("dddd, D MMMM, YYYY");
                return `${allDayLabel}, ${time}`;
            } else {
                return date.format("dddd, D MMMM, YYYY HH:mm");
            }
        },
        getDurationText(buf, i, isHtml, modelData) {
            const { time, allDay } = modelData;
            if (!time || time.length != 2) {
                return i;
            }
            moment.locale(this.localeCurrentValue);
            const startTime = moment(time[0]);
            const endTime = moment(time[1]);
             // this.formatter(isHtml, this.$t("zimbra.zmMsg.timeLabel"), whenSummary, "");
             if (this.isMultiDay(startTime, endTime)) {
                const startLabel = this.formatDateFull(allDay, startTime);
                const endLabel = this.formatDateFull(allDay, endTime);
                buf[i++] = this.formatter(isHtml, this.$t("zimbra.zhMsg.startLabel"), startLabel, "");
                buf[i++] = "\n";

                buf[i++] = this.formatter(isHtml, this.$t("zimbra.zhMsg.endLabel"), endLabel, "");
                buf[i++] = "\n";
            } else {
                const date = startTime.format("dddd, D MMMM, YYYY");
                const startHours = startTime.format("HH:mm");
                const endHours = endTime.format("HH:mm");
                buf[i++] = this.formatter(isHtml, this.$t("zimbra.zmMsg.timeLabel"), `${date}, ${startHours} - ${endHours}`, "");
                buf[i++] = "\n";
            }
            return i;
        },
        /**
         *
         * @param {*} modelData
         * @returns
         */
         getBlur(modelData) {
            const { customRepeat, time } = modelData;
            if (!time) {
                return "";
            }
            const startDate = time[0] || new Date();
            if (!customRepeat) {
                return "";
            }
            const {
                daily,
                weekly,
                monthly,
                yearly,
                recurDailyEveryNumDays,
                recurWeeklyEveryWeekday,
                recurWeeklyEveryNumWeeksDate,
                recurMonthlyEveryNumMonthsDate,
                recurMonthlyEveryNumMonthsWeekDays,
                recurYearlyEveryDate,
                recurYearlyEveryMonthWeekDays,
                recurEndType,
                recurEndNumber,
                recurEndByDate
            } = customRepeat;
            const every = [];
            // const SERVER_WEEK_DAYS = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"]
            switch(customRepeat.freq) {
                case CALENDAR_FREQ_TYPE.DAILY:
                    if (daily === CAL_RECUR_DAILY_TYPE.EVERY_WEEKDAY) {
                        every.push(i18n.t("zimbra.zhMsg.recurDailyEveryWeekday"));
                    } else if (daily === CAL_RECUR_DAILY_TYPE.EVERY_DAY) {
                        every.push(i18n.t("zimbra.zhMsg.recurDailyEveryDay"));
                    } else if (recurDailyEveryNumDays) {
                        every.push(i18n.t("zimbra.ztMsg.recurDailyEveryNumDays", [recurDailyEveryNumDays[0]]));
                    }
                    break;
                case CALENDAR_FREQ_TYPE.WEEKLY:
                    if (weekly === CAL_RECUR_WEEKLY_TYPE.EVERY_WEEKDAY) {
                        const day = DATASOURCE_TIME_DAYS.find(e => e.value === recurWeeklyEveryWeekday[0]) || {};
                        every.push(i18n.t("zimbra.zhMsg.recurWeeklyEveryWeekday", [day.label]));
                    } else if (recurWeeklyEveryNumWeeksDate) {
                        const days = DATASOURCE_TIME_DAYS.filter(e => recurWeeklyEveryNumWeeksDate[1].includes(e.value)) || [];
                        every.push(i18n.t("zimbra.zmMsg.recurWeeklyEveryNumWeeksDate", [recurWeeklyEveryNumWeeksDate[0], days.map(e => e.label).join(", "), ""]));
                    }
                    break;
                case CALENDAR_FREQ_TYPE.MONTHLY:
                    if (monthly === CAL_RECUR_MONTHLY_TYPE.EVERY_NUM_MONTHS_DATE) {
                        const date = recurMonthlyEveryNumMonthsDate[0];
                        const count = recurMonthlyEveryNumMonthsDate[1];
                        every.push(i18n.t("zimbra.ztMsg.recurMonthlyEveryNumMonthsDate", [date, count]));
                    } else if (recurMonthlyEveryNumMonthsWeekDays) {
                        const _pos = recurMonthlyEveryNumMonthsWeekDays[0];
                        const _date = recurMonthlyEveryNumMonthsWeekDays[1];
                        const _count = recurMonthlyEveryNumMonthsWeekDays[2];
                        const localeKey = "zimbra.zmMsg.recurMonthlyEveryNumMonthsWeekDays";
                        const msg = i18n.t(localeKey);
                        const regExp = /(\{[^}]+\})/gi;
                        const match = msg.match(regExp);
                        const posLabel = this.getLabelValue(localeKey, match[0], _pos);
                        const dateLabel = this.getLabelValue(localeKey, match[1], _date);
                        every.push(i18n.t("zimbra.ztMsg.recurMonthly", [posLabel, dateLabel, _count]));
                    }
                    break;
                case CALENDAR_FREQ_TYPE.YEARLY:
                    if (yearly === CAL_RECUR_YEARLY_TYPE.EVERY_DATE) {
                        const month = DATASOURCE_MONTHS.find(e => e.value === recurYearlyEveryDate[0]);
                        const count = recurYearlyEveryDate[1];
                        every.push(i18n.t("zimbra.ztMsg.recurYearlyEveryDate", [month?.label, count]));
                    } else if (recurYearlyEveryMonthWeekDays) {
                        const _pos = recurYearlyEveryMonthWeekDays[0];
                        const _date = recurYearlyEveryMonthWeekDays[1];
                        const _month = recurYearlyEveryMonthWeekDays[2];
                        const localeKey = "zimbra.zmMsg.recurYearlyEveryMonthWeekDays";
                        const msg = i18n.t(localeKey);
                        const regExp = /(\{[^}]+\})/gi;
                        const match = msg.match(regExp);
                        const posLabel = this.getLabelValue(localeKey, match[0], _pos);
                        const dateLabel = this.getLabelValue(localeKey, match[1], _date);
                        const monthLabel = this.getLabelValue(localeKey, match[2], _month);
                        every.push(i18n.t("zimbra.ztMsg.recurYearlyEveryMonth", [posLabel, dateLabel, monthLabel]));
                    }
                    break;
            }

            moment.locale(this.localeCurrentValue);
            // Ngày kết thúc
            if (recurEndType === RECUR_END_INTERVAL_TYPE.NONE) {
                every.push(i18n.t("zimbra.zhMsg.recurEndNone"));
            } else if (recurEndType === RECUR_END_INTERVAL_TYPE.NUMBER) {
                const number = recurEndNumber[0];
                every.push(i18n.t("zimbra.zhMsg.recurEndNumber", [number]));
            } else {
                const date = recurEndByDate[0];
                every.push(i18n.t("zimbra.zhMsg.recurEndByDate", [moment(date).format("DD MMMM, YYYY")]));
            }
            // Ngày hiệu lực
            every.push(i18n.t("zimbra.ztMsg.recurStart", [moment(startDate).format("DD MMMM, YYYY")]));
            return every.join(" ");
        },
        getLabelValue(localeKey, config, value) {
            const trimLocalString = config.substring(1, config.length - 1);
            const a = trimLocalString.split(",");
            const idx = parseInt(a.shift());
            const type = a.shift();
            const extraValue = a.shift();
            const datasource = this.getDatasource(localeKey, idx, type, extraValue) || [];
            const selected = datasource.find(e => e.value === value);
            return selected ? selected.label : "";
        },
        getDatasource(localeKey, valueOrder, type, extraValue) {
            let datasource = [];
            if (type === "choice") {
                const options = extraValue.split("|");
                options.forEach((item) => {
                    const option = item.split("#");
                    datasource.push({
                        value: parseInt(option[0], 10),
                        label: option[1] || this.$t("zimbra.zmMsg.days"),
                    });
                    const _first = datasource.filter(e => e.value > 0);
                    const _last = datasource.filter(e => e.value <= 0);
                    datasource = _first.concat(_last);
                });
                if (localeKey === "zimbra.zmMsg.recurMonthlyEveryNumMonthsNumDay" ||
                    (valueOrder === 1 && localeKey === "zimbra.zmMsg.recurMonthlyEveryNumMonthsWeekDays") ||
                    (valueOrder === 1 && localeKey === "zimbra.zmMsg.recurYearlyEveryMonthWeekDays")
                ) {
                    datasource = datasource.concat(DATASOURCE_TIME_DAYS);
                }
            } else if (
                (type === "date" && extraValue === "EEEE") ||
                (type === "list" && extraValue === "date")
            ) {
                datasource = DATASOURCE_TIME_DAYS;
                if (localeKey=== "zimbra.zmMsg.recurWeeklyEveryWeekday") {
                    datasource = datasource.concat([
                        {
                            value: ["MO", "WE", "FR"].join("|"),
                            label: this.titleMonWedFri
                        },
                        {
                            value: ["TU", "TH"].join("|"),
                            label: this.titleTueThu
                        },
                        {
                            value: ["SA", "SU"].join("|"),
                            label: this.titleSatSun
                        },
                    ])
                }
            } else if (type === "date" && extraValue === "MMMM") {
                datasource = DATASOURCE_MONTHS;
            }
            return datasource;
        },
        isChangeName() {
            // fix lại
            return false;
        },
        getSummary(modelData, isHtml) {
            const { freq, loc, name } = modelData;

            const buf = [];
            let _recurBlurb = "";
            let i = 0;
            if (freq !== CALENDAR_FREQ_TYPE.NONE) {
                // TH có lặp
                _recurBlurb = this.getBlur(modelData);
            }

            if (isHtml) {
                buf[i++] = "<p>\n<table border='0'>\n";
            }

            // const subjectLabel = this.$t("zimbra.zhMsg.subjectLabel", [subjectLabel]);
            const apptModifiedStamp = this.isChangeName() ? this.$t("zimbra.zhMsg.apptModifiedStamp", [apptModifiedStamp]) : "";
            buf[i++] = this.formatter(isHtml, this.$t("zimbra.zhMsg.subjectLabel"), name, apptModifiedStamp);
            buf[i++] = "\n";

            const orgName = this.emailAddress;
            if (orgName) {
                buf[i++] = this.formatter(isHtml, this.$t("zimbra.zhMsg.organizerLabel"), orgName, "");
                buf[i++] = "\n";
            }

            if (isHtml) {
                buf[i++] = "</table>";
            }
            buf[i++] = "\n";
            if (isHtml) {
                buf[i++] = "<p>\n<table border='0'>\n";
            }

            const locationSummary = loc;
            if (locationSummary) {
                buf[i++] = this.formatter(isHtml, this.$t("zimbra.zhMsg.locationLabel"), locationSummary, "");
                buf[i++] = "\n";
            }

            // const whenSummary = this.getDurationText(modelData);
            // if (whenSummary) {
            //     buf[i++] = this.formatter(isHtml, this.$t("zimbra.zmMsg.timeLabel"), whenSummary, "");
            //     buf[i++] = "\n";
            // }

            i = this.getDurationText(buf, i, isHtml, modelData);

            if (_recurBlurb) {
                buf[i++] = this.formatter(isHtml, this.$t("zimbra.zhMsg.repeatLabel"), _recurBlurb, "");
                buf[i++] = "\n";
            }

            if (isHtml) {
                buf[i++] = "</table>\n";
            }
            buf[i++] = isHtml ? "<div>" : "\n\n";
            buf[i++] = "*~*~*~*~*~*~*~*~*~*";
            buf[i++] = isHtml ? "</div><br>" : "\n\n";
            return buf.join("");
        },

        /**
         * hàm chuyển chuyển lịch
         * @param {*} formMove
         */
        async moveFolderCalendar(idSource, nameSource, idFolder, nameFolder){
            const formData = {
                id: idSource,
                l: idFolder.toString(),
                op: FOLDER_ACTION.MOVE,
            }
            await ZimbraMailService.folderActionRequest({
                    folder: `<urn1:action id="${formData.id}" op="${formData.op}" l="${formData.l}"></urn1:action>`
                }
            );
            // Thông báo thành công
            this.$notify({
                title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                message: this.$t("zimbra.zmMsg.actionMove", ["", nameSource, nameFolder]),
                type: "success",
            });
        },

        /**
         * hàm chuyển chuyển vào thùng rác
         * @param {*} formMove
         */
        async moveCalendarToTrash(idSource, nameSource){
            await ZimbraMailService.folderActionRequest({
                    folder: `<urn1:action id="${idSource.toString()}" op="${FOLDER_ACTION.TRASH}"></urn1:action>`
                }
            );
            // Thông báo thành công
            this.$notify({
                title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                message: this.$t("zimbra.zmMsg.actionMove", ["", nameSource, this.$t("zimbra.zhMsg.FOLDER_LABEL_3")]),
                type: "success",
            });
        },


        /**
         * Hàm xóa hẳn thư mục lịch
         * @param {*} formData
         */
        async deleteCalendarFolder(idSource, nameSource) {
            let isReload = true;
            await this.$confirm(
                this.$t("zimbra.zmMsg.confirmDeleteCalendar", [nameSource]),
                this.$t("zimbra.zhMsg.confirm"),
                {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    type: "error",
                }
            ).then(async () => {
                await ZimbraMailService.folderActionRequest({
                        folder: `<urn1:action id="${idSource.toString()}" op="${FOLDER_ACTION.DELETE}"></urn1:action>`
                    }
                );
                // Tìm kiếm lại sau khi xóa
                this.$root.$emit("onSearchAppointmentRequest");
            }).catch(() => isReload = false);
            return isReload;
        },

        /**
         * hàm thêm mới thư mục lịch
         * @param {*} formMformCreateove
         */
        async createCalendarFolder(formCreate){
            await ZimbraMailService.createFolderRequest({
                    name: formCreate.name,
                    f:  (formCreate.isExcludeFromFreeBusy? "b": "") + "#",
                    l: formCreate.parentFolderId? formCreate.parentFolderId.toString(): ROOT_CALENDAR_FOLDER_ID,
                    rgb: formCreate.rgb? formCreate.rgb.toString(): "",
                    view: FOLDER_VIEW.APPOINTMENT
                }
            ).then((res) => {
                if (res.status == STATUS_REQUEST.SUCCESS) {
                    this.$notify({
                        title: this.$t( "zimbra.zmMsg.dataSourceTestSuccess"),
                        message: this.$t( "zimbraNokey.zimbra_sidebar_messageSuccessAddFolder"),
                        type: "success",
                    });
                    const itemAddResponse = this.getResponseBody(res)["CreateFolderResponse"];
                    this.$root.$emit("handleCheckCalendarTree", itemAddResponse.folder)
                }
            }
            ).catch((error) => {
                const { detail } = this.getResponseBody(error.response)[
                    "soap:Fault"
                ];
                if (detail.Error.Code) {
                    if (detail.Error.Code == "service.PERM_DENIED") {
                        this.$alert(
                            this.$t("zimbra.zmMsg.errorPermission"),
                            this.$t("zimbraNokey.zimbra_sidebar_criticalMsg"),
                            {
                                confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                                type: "error",
                            }
                        );
                    }
                }
            });
            // Load lại tree
            this.$root.$emit("onInnitCalendarTree");
        },

        /**
         * hàm lưu cấu hình các lịch đã chọn
         * @param {*} listConfigCheck
         */
        async saveConfigCheckFolderCalendar(listConfigCheck){
            if(!listConfigCheck) return;
            const lstXmlStringForm = [];
            listConfigCheck.forEach((x) => {
                const op = x.isCheck? FOLDER_ACTION.CHECK: FOLDER_ACTION.UN_CHECK;
                const xml = `<urn1:action id="${x.id}" op="${op}"></urn1:action>`
                lstXmlStringForm.push({
                    path: "folderActionRequest",
                    content: JSON.stringify({
                        folder: xml,
                    }),
                });
            });
            const formDataShare = {
                listRequest: lstXmlStringForm
            };
            await CompileService.batchRequest(formDataShare);
        },
        // truncateTime
        truncateMinuteTime(time) {
            return time.startOf('minute').clone();
        },
        checkIsConflictTime(iStart, iEnd, start, end) {
            // const isMatched1 = iStart.startOf('minute').isBefore(end.startOf('minute'));
            // const isMatched2 = start.startOf('minute').isBefore(iEnd.startOf('minute'));
            const isMatched1 = iStart.isBefore(end);
            const isMatched2 = start.isBefore(iEnd);
            return isMatched1 && isMatched2;
        },
        checkIsBetweenTime(iCheck, start, end, granularity = undefined, inclusivity = "()") {
            return iCheck.isBetween(start, end, granularity, inclusivity);
        },
        /**
         * hàm gọi nhiều api sửa thuọc tính (Mỗi thuộc tính là 1 api)
         * @param {*} lstProperty
         */
        async saveCalendarProperties(formNew, formOld){
            // Tạo các XML rename, color, bận - fb
            const lstXML = [];
            const _id = formNew.id.toString()
            if(formNew.name != formOld.name) {
                lstXML.push(`<urn1:action id="${_id}" op="${FOLDER_ACTION.RENAME}"  name="${formNew.name}"></urn1:action>`)
            }
            if(formNew.rgb != formOld.rgb) {
                lstXML.push(`<urn1:action id="${_id}" op="${FOLDER_ACTION.COLOR}" rgb="${formNew.rgb}"></urn1:action>`)
            }
            if(formNew.isExcludeFromFreeBusy != formOld.isExcludeFromFreeBusy) {
                lstXML.push(`<urn1:action id="${_id}" op="${FOLDER_ACTION.FB}" excludeFreeBusy="${formNew.isExcludeFromFreeBusy? 1: 0}"></urn1:action>`)
            }

            // Tạo danh sách api để gọi
            const lstXmlStringFormAPI = [];
            lstXML.forEach((xml) => {
                lstXmlStringFormAPI.push({
                    path: "folderActionRequest",
                    content: JSON.stringify({
                        folder: xml,
                    }),
                });
            });
            const formDataShare = {
                listRequest: lstXmlStringFormAPI
            };
            await CompileService.batchRequest(formDataShare);
        },

        /**
         * hàm kiểm tra form nhập có khác nhau không
         * @param {*} formOld
         * @param {*} formNew
         */
        isPropertiesChange(formOld, formNew){
            if(formOld.name == formNew.name && formOld.rgb == formNew.rgb && formOld.isExcludeFromFreeBusy == formNew.isExcludeFromFreeBusy){
                return false;
            }
            return true;
        },

        /**
         * Hàm thêm, xóa tag khỏi lịch
         * @param {*} listAssignIds
         * @param {*} tagId
         * @param {*} isAssgin
         */
        async handledAssginOrRemoveTagCalendar(listAssignIds, tagName, isAssgin) {
            const idsStr = listAssignIds.toString();
            const action = isAssgin? ITEM_ACTION.TAG: ITEM_ACTION.REMOVE_TAG
            // Gọi api gán thẻ
            await ZimbraMailService.itemActionRequest({
                id: idsStr,
                tn: tagName,
                op: action
            })

            // Hiển thị thông báo thành công
            const msg = isAssgin
            ? this.$t("zimbra.zmMsg.actionTag", [listAssignIds.length, this.$t("zimbra.zhMsg.ALT_MSG_STATUS_APPT"), tagName])
            : this.$t("zimbra.zmMsg.actionUntag", [listAssignIds.length, this.$t("zimbra.zhMsg.ALT_MSG_STATUS_APPT"), tagName]);
            this.$notify({
                title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                message: msg,
                type: "success",
            });
        },

        /**
         * Hàm xóa tất cả các thẻ
         * @param {*} listAssignIds
         */
        async handledRemoveAllTag(listAssignIds) {
            const idsStr = listAssignIds.toString();
            // Gọi xóa tất cả thẻ
            await ZimbraMailService.itemActionRequest({
                id: idsStr,
                op: ITEM_ACTION.UPDATE,
                isRemoveAllTag: 1,
            })

            // Hiển thị thông báo thành công
            this.$notify({
                title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                message: this.$t("zimbra.zmMsg.actionRemoveTags", [listAssignIds.length, this.$t("zimbra.zhMsg.ALT_MSG_STATUS_APPT")]),
                type: "success",
            });
        },

        /**
         * Hàm xóa cuộc hẹn trong thùng rác
         * @param {*} formData
         */
        async deleteAppointmentRequest(apptId) {
            this.$confirm(
                this.$t("zimbra.zmMsg.confirmCancelApptListPermanently"),
                this.$t("zimbra.zmMsg.confirmDeleteApptTitle"),
                {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    type: "error",
                }
            ).then(async () => {
                await ZimbraMailService.itemActionRequest({
                    id: apptId,
                    op: ITEM_ACTION.DELETE
                });
                this.$notify({
                    title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: this.$t("zimbra.zmMsg.actionDelete", { 0: 1, 1: this.$t("zimbra.zhMsg.ALT_MSG_STATUS_APPT") }),
                    type: "success",
                });
                // Tìm kiếm lại sau khi xóa
                this.$root.$emit("onSearchAppointmentRequest");
            }).catch(() => { });
        },

        /**
         * là mở cuộc hẹn trường hợp
         * @param {} typeRecurring
         */
        isApptInstance(typeRecurring) {
            return typeRecurring == CAL_OPEN_RECURRING_ITEM.OPEN_INSTANCE;
        },

        /**
         * là mở cả chuỗi
         * @param {*} typeRecurring
         */
        isApptSeries(typeRecurring) {
            return typeRecurring == CAL_OPEN_RECURRING_ITEM.OPEN_APPT_SERIES;
        },

        /**
         * Hàm kiểm tra xem mình có phải chủ cuộc hẹn không
         */
        isOwnAppt(apptData) {
            return apptData?.or?.a == this.emailAddress;
        },

        /**
         * Hàm thực hiện phản hồi cuộc hẹn trên hệ thống lịch
         * @param {*} verb
         * @param {*} updateOrganizer
         */
        sendCalendarInviteReplay(apptData, verb, updateOrganizer) {
            // Tạo subject + Nội dung thư gửi của cuộc hẹn
            const _su = apptData.name
            const _mp = [
                    {
                        ct: ZmMimeTable.MULTI_ALT,
                        mp: [
                            {
                                ct: ZmMimeTable.TEXT_PLAIN,
                                content: ""
                            },
                            {
                                ct: ZmMimeTable.TEXT_HTML,
                                content: "",
                            },
                        ],
                    },
                ];
            const _e = [{t: "t", a: apptData.or.a}];
            const _m = {su: _su, mp: _mp, e: _e}

            const {invId, exceptId} = apptData
            const formData = {
                id: invId.toString(),
                compNum: "0", // Thứ tự của thằng con trong danh sách lặp lịch???
                verb: verb,
                updateOrganizer: updateOrganizer ? "TRUE" : "FALSE",
                exceptId: exceptId,
                m: _m
            };
            return ZimbraMailService.sendInviteReplyRequest(formData).then(() => {
                // Thông báo thành công
                const msg = this.getCalContentReply(verb);
                this.$notify({
                    title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: msg,
                    type: "success",
                });
            })
        },

        /**
         * Hàm lấy thôgn báo trả về thành công theo loại hành động
         */
        getCalContentReply(verb) {
            let msg = "";
            switch (verb) {
                case CAL_OPERATOR_REPLY_INV.ACCEPT:
                    msg = this.$t("zimbra.zmMsg.inviteAccepted");
                    break;
                case CAL_OPERATOR_REPLY_INV.TENTATIVE:
                    msg = this.$t("zimbra.zmMsg.inviteAcceptedTentatively");
                    break;
                case CAL_OPERATOR_REPLY_INV.DECLINE:
                    msg = this.$t("zimbra.zmMsg.inviteDeclined");
                    break;
            }
            return msg;
        },

        /**
         * Get thông tin chi tiết cuộc hẹn
         * @param {*} invId
         */
        async getInfoAppointment(invId, ridZ) {
            const formData = {
                id: `${invId}`,
                ridZ: `${ridZ}`,
            };
            const msgRequest = await ZimbraMailService.getMsgRequest(formData);
            const msgResponse = this.getResponseBody(msgRequest)[
                "GetMsgResponse"
            ];
            return msgResponse
        },

        /**
         * Hàm chuyển tiếp cuộc hẹn
         * @param {*} invId
         */
        async forwardAppointment(apptForm) {
            // Cấu hình chung
            const formForward = {
                id: apptForm.apptId.toString(),
                exceptId: apptForm.inv.comp[0].exceptId,
                m: {
                    e: apptForm.e,
                    mp: apptForm.mp,
                    su: apptForm.su
                }
            }

            // Gọi api chuyển tiếp
            await ZimbraMailService.forwardAppointmentRequest(formForward);
            // Thực hiện thông báo thành công
            this.$notify({
                title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                message: this.$t("zimbra.zmMsg.forwardInviteSent"),
                type: "success",
            });
        },

        /**
         * Hàm nạp thông tin tài khoản zimlet
         */
        async initAcountWebexZimlet() {
            // Lấy thông tin  tài khoản webex
             ZimbraMailService.getMailboxMetadataRequest({ section: "zwc:webexZimletAccountPreferences" }).then(res => {
                const { meta } = CommonUtils.methods.getResponseBody(res).GetMailboxMetadataResponse;
                if (meta && meta.a) {
                    let mapAccount = new Map();
                    // Duyệt dữ liệu trả về để tạo danh sách 5 user
                    meta.a.forEach(item => {
                        const propName = item.n.slice(0, -1)
                        const key = item.n.at(-1)
                        const value = item.content == "N/A"? "": item.content
                        if(!mapAccount.get(key)) {
                            mapAccount.set(key, {})
                        }
                        mapAccount.get(key)[propName] = value;
                    })
                    mapAccount = new Map([...mapAccount.entries()].sort());
                    this.$store.commit("SET_LST_ACCOUNT_ZIMLET_WEBEX_ZIMLET", [...mapAccount.values()]);
                }
            });
        },

        /**
         * Hàm lấy thông tin link rảnh bận theo loại: HTML, ICS,..
         */
        getContentCalendarLink(_type) {
            const prefix = `${this.$configZimbra.ENDPOINT.importExportService}/${this.emailAddress}?`
            let typeContent = ""
            switch (_type) {
                case CALENDAR_LINK_TYPE.HTML:
                    typeContent = "fmt=freebusy"
                    break;
                case CALENDAR_LINK_TYPE.ICS:
                    typeContent = "fmt=ifb"
                    break;
                case CALENDAR_LINK_TYPE.EVENT_ICS:
                    typeContent = "fmt=ifb&fbfmt=event"
                    break;
                default:
                    typeContent = "fmt=freebusy"
            }
            return prefix + typeContent;
        },

        /**
         * Get thông tin Tùy chọn
         */
         async initDataPreferencesWebexZimlet() {
            ZimbraMailService.getMailboxMetadataRequest({ section: "zwc:webexZimletGeneralPreferences" }).then(res => {
                const data = CommonUtils.methods.getResponseBody(res).GetMailboxMetadataResponse.meta.a;
                const form = {}
                for (var key in data) {
                    const item = data[key];
                    form[item.n] = item.content;
                }
                this.$store.commit("SET_PREFERENCES_ZIMLET_WEBEX_ZIMLET", form);
            })
        },

        /**
         * Hàm lấy thông tin các cuộc hẹn trong lịch cụ thể
         */
        getAppointmentCalendarLink(treeNode) {
            // Nếu là calendar thường thì bỏ ký tự đầu tiên đi vì là dầu /
            let pathSearch = treeNode.absFolderPath.substring(1)
            pathSearch = encodeURIComponent(pathSearch)
            const prefix = `${this.$configZimbra.ENDPOINT.importExportService}/${this.emailAddress}/${pathSearch}.html?`
            let locale = `tz=${this.timeZoneCurrent.content}`
            return prefix + locale;
        },

        /**
         * Hàm kiểm tra có file hay không
        */
        isHaveFile(item) {
            return item.f && item.f.includes(EMAIL_FLAG.ATTACHMENT);
        },

        /**
         * Hàm kiểm tra có phải folder share hay không
        */
        isCalendarFolderShare(item) {
            return item.l && item.l.toString().split(":").length > 1;
        },

        /**
         * Hàm lấy ra thời điểm đầu ngày
         * @param {*} time
         */
        startOfDay(time) {
            if (!time) {
                return moment().startOf('day').clone();
            }
            return time.startOf('day').clone();
        },

        /**
         * Hàm xử lý xóa những cuộc hẹn tương lai trong series
         */
         async deleteAppointmentFuture(apptData) {
            // Lấy dữ liệu cuộc hẹn
            const resAppointment = await this.getAppointmentById(apptData.invId)
            const appointDetail = resAppointment.m.inv.comp;
            const startDeleteDay = moment(new Date(apptData.inst.s)).subtract(1, "days").format("yyyyMMDDTHHmmss");

            // Nếu là cuộc hẹn đầu tiên của series thì thực hiện xóa all
            const apptDateStr = moment(new Date(apptData.inst.s)).format("yyyyMMDD")
            const apptSeriesDateStr = appointDetail.s.u? moment(new Date(appointDetail.s.u)).format("yyyyMMDD"): appointDetail.s.d;

           if(apptDateStr == apptSeriesDateStr) {
                this.cancelAppointmentSeriesRequest(apptData.invId)
                return;
            }

            // Thiết định ngày kết thúc trong cấu hình cuộc hẹn định kỳ
            const _until = { d: startDeleteDay}
            appointDetail.recur.add.rule =  {...appointDetail.recur.add.rule, until: _until};

            // // Chuyển đổi kiểu dữ liệu phù hợp
            appointDetail.alarm = [].concat(appointDetail.alarm)
            if(!appointDetail.s.u){
                appointDetail.s.d = Number(appointDetail.s.d).toString();
            }
            if(!appointDetail.e.u){
                appointDetail.e.d = Number(appointDetail.e.d).toString();
            }
            if(appointDetail.recur.add.rule.byday?.wkday) {
                appointDetail.recur.add.rule.byday.wkday = [].concat(appointDetail.recur.add.rule.byday.wkday)
            }

            // // Tạo form cập nhật
            const modifyAppointmentRequest = {
                comp: "0",
                id: apptData.invId,
                m: {
                    // attach: {} -- thông tin file đính kèm ==? chưa rõ
                    // e: [] -- thông tin email ==> chưa rõ
                    inv: {
                        comp: [].concat(appointDetail)
                    },
                    l: resAppointment.m.l.toString(),
                    // mp: {} 	Mime part information không rõ
                },
                ms: resAppointment.ms,
                rev: resAppointment.rev
            };

            // Thực hiện cập nhật lại cuộc hẹn để xóa những series tương lai
            this.modifyAppointmentRequest(modifyAppointmentRequest).then(() => {
                // Thông báo thành công
                this.$notify({
                    title: this.$t("zimbra.zmMsg.dataSourceTestSuccess"),
                    message: this.$t("zimbra.zmMsg.actionDelete", { 0: 1, 1: this.$t("zimbra.zhMsg.ALT_MSG_STATUS_APPT") }),
                    type: "success",
                });
                // Thực hiện tìm kiếm lại list
                this.$root.$emit('onSearchAppointmentRequest')
            });
        },
        /**
         * Hàm xóa hẳn thư mục lịch đã bị thu hồi
         */
         async deleteRegainCalendarFolder(idSource, nameSource) {
            let isReload = true;
            await this.$confirm(
                this.$t("zimbra.zmMsg.confirmDeleteMissingFolder",[nameSource]),
                this.$t("zimbra.ztMsg.alertFeedReplyTitle"),
                {
                    confirmButtonText: this.$t("zimbra.zmMsg.mobileStatusNeedProvision"),
                    cancelButtonText: this.$t("zimbra.zhMsg.actionTaskCancelTT"),
                    type: "warning",
                }
            ).then(async () => {
                await ZimbraMailService.folderActionRequest({
                        folder: `<urn1:action id="${idSource.toString()}" op="${FOLDER_ACTION.DELETE}"></urn1:action>`
                    }
                );
                // Cập nhật lại dữ liệu cây
                this.getCalFolder().then(calFolderRes  => {
                    this.$store.commit('SET_CAL_TREE_FOLDER', calFolderRes);
                    this.$store.dispatch("getTreeFolder")
                });
            }).catch(() => isReload = false);
            return isReload;
        },
        getParticipationStatusText(ptst, isNew = true) {
            let text = "";
            switch(ptst) {
                case CAL_PARTICIPATION_STATUS.ACCEPT:
                    text = this.$t("zimbra.zhMsg.apptPtstAC");
                    break;
                case CAL_PARTICIPATION_STATUS.TENTATIVE:
                    text = this.$t("zimbra.zhMsg.apptPtstTE");
                    break;
                case CAL_PARTICIPATION_STATUS.DECLINED:
                    text = this.$t("zimbra.zhMsg.apptPtstDE");
                    break;
                case CAL_PARTICIPATION_STATUS.NEEDS_ACTION:
                    // text = this.$t("zimbra.zmMsg.needsAction");
                    // text = this.$t("zimbra.zhMsg.apptPtstNEW");
                    text = isNew ? this.$t("zimbra.zhMsg.apptPtstNEW") : this.$t("zimbra.zmMsg.needsAction");
                    break;
            }
            return text;
        },
        getFreeBusyText(fb) {
            const config = DATASOURCE_FREE_BUSY_FULL.find(e => e.value === fb);
            return config ? config.label : "";
        },
        isRoleManagerOrAdmin(_perm) {
           return ["rwidx", "rwidxa", "rwidxc", "rwidxac"].includes(_perm)
        },

        /**
         * hàm kiểm tra cuộc hẹn được thêm qua lịch ngoài
         */
        checkApptFromExternalCal (idCal) {
            const calFolders = {id: 1, folder: this.$store.getters.calTreeFolders}
            const calendarId = this.isCalendarFolderShare({l: idCal}) ? idCal.split(":")[1] : idCal;
            const calData = this.isCalendarFolderShare({l: idCal})
                ? this.searchNodeShareById(calFolders, calendarId)
                : this.searchNodeById(calFolders, calendarId)
            return calData?.url !== undefined
        },
    }
};
