<template>
    <div class="row-input row-input-email">
        <span v-if="type" class="head">{{ type }}</span>
        <div class="group-email" @drop="onDrop" @dragover.prevent>

            <vue-tags-input slot="reference" ref="inputTag" v-model="tag" :tags="tags"
                :autocomplete-items="filteredItems" :placeholder="placeholder" :add-only-from-autocomplete="true"
                @tags-changed="tagsChange" @before-adding-tag="checkTag" @before-saving-tag="beforeSavingTag"
                :avoid-adding-duplicates="true" :delete-on-backspace="true" class="zm-tags-input"
                @input="ajustAutocompleteDropDown" @focus="ajustAutocompleteDropDown" :disabled="disabled">
                <div slot="tag-center" slot-scope="props">
                    <base-address-popover v-model="props.tag" :emailTags="tags" @update-email-tags="tagsChange"
                      :type="type">
                    </base-address-popover>
                </div>
                <div slot="autocomplete-item" slot-scope="props" class="my-item" @click="props.performAdd(props.item)">
                    <ItemAddressSelector :value="props.item" :multiple="true" fieldLabel="text"
                        @selectMembers="(selectedItem) => selectMembers(props, selectedItem)"
                        @selectAllMembers="(listMembers) => selectAllMembers(props, listMembers)" />
                </div>
            </vue-tags-input>
            <!-- <div>
                    <i class="fas fa-sync-alt pr-2 zm-transform-rotate"></i>
                    {{ $t("zimbra.zmMsg.autocompleteWaiting")}}
                </div>
            </el-popover> -->
            <!-- :allow-edit-tags="true" -->
            <div class="btn-ip-email">
                <el-tooltip class="item" effect="dark" :content="$t('zimbra.zhMsg.cc')" placement="top-start">
                    <a v-if="showCc" href="javascript:;" class="btn-Cc"
                        @click="$emit('handleShowAddressSelector', 'cc')">
                        Cc
                    </a>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" :content="$t('zimbra.zhMsg.bcc')" placement="top-start">
                    <a v-if="showBcc" href="javascript:;" class="btn-Bcc"
                        @click="$emit('handleShowAddressSelector', 'bcc')">
                        Bcc
                    </a>
                </el-tooltip>
            </div>
        </div>
        <el-tooltip class="item" effect="dark" :content="$t('zimbra.zmMsg.selectAddresses')" placement="top-start"
            v-if="showDialog">
            <a href="javascript:;" :class="['btn-book-email button-hover',
                disabled ? 'noclick' : ''
            ]" @click="openDialogAddressSeletor()"></a>
        </el-tooltip>
        <context-menu id="context-menu" ref="ctxMenu" @ctx-open="onCtxOpen" @ctx-close="onCtxClose"
            @ctx-cancel="onCtxClose">
            <div class="right-menu-mail box-control-inbox address-menu">
                <ul v-if="showMenuAction">
                    <li>
                        <a class="menu-item" href="javascript:;"
                            @click="handleAddressAction(ADDRESS_ACTION_TYPE.DELETE)">
                            <i class="ic el-icon-circle-close" style="color: red;height: unset "></i>
                            <span>{{ $t("zimbra.zhMsg.actionDelete") }}</span>
                        </a>
                    </li>
                    <li>
                        <a class="menu-item" href="javascript:;" @click="handleAddressAction(ADDRESS_ACTION_TYPE.COPY)">
                            <i class="ic ic-folder-copy" style="margin-left: -5px;margin-right: 10px;"></i>
                            <span>{{ $t("zimbra.zmMsg.copy") }}</span>
                        </a>
                    </li>
                    <li>
                        <a class="menu-item" href="javascript:;" @click="handleAddressAction(ADDRESS_ACTION_TYPE.EDIT)">
                            <i class="ic fi-rr-pencil"></i>
                            <span>{{ $t("zimbra.zhMsg.edit") }}</span>
                        </a>
                    </li>
                    <li>
                        <a class="menu-item" :class="`${this.tagActionIsGroup ? '' : 'noclick'}`" href="javascript:;"
                            @click="handleAddressAction(ADDRESS_ACTION_TYPE.EXTEND)">
                            <i class="ic el-icon-rank" style="height: unset"></i>
                            <span>{{ $t("zimbra.zmMsg.expand") }}</span>
                        </a>
                    </li>
                    <li>
                        <a class="menu-item" :class="`${this.tagActionIsGroup ? 'noclick' : ''}`" href="javascript:;"
                            @click="handleAddressAction(ADDRESS_ACTION_TYPE.EDIT_CONTACT)">
                            <i class="ic fi-rr-pencil"></i>
                            <span>{{ $t("zimbra.zhMsg.AB_EDIT_CONTACT") }}</span>
                        </a>
                    </li>
                    <div v-if="type">
                        <hr style="margin: 0;">
                        <li>
                            <a class="menu-item"
                                :class="`${this.disabledAction($t('zimbra.zhMsg.FILT_COND_HEADER_to')) ? 'noclick' : ''}`"
                                href="javascript:;" @click="handleAddressAction(ADDRESS_ACTION_TYPE.MOVE_FROM)">
                                <span>{{ $t("zimbra.zmMsg.moveToTo") }}</span>
                            </a>
                        </li>
                        <li>
                            <a class="menu-item"
                                :class="`${this.disabledAction($t('zimbra.zhMsg.cc')) ? 'noclick' : ''}`"
                                href="javascript:;" @click="handleAddressAction(ADDRESS_ACTION_TYPE.MOVE_CC)">
                                <span>{{ $t("zimbra.zmMsg.moveToCc") }}</span>
                            </a>
                        </li>
                        <li>
                            <a class="menu-item"
                                :class="`${this.disabledAction($t('zimbra.zhMsg.bcc')) ? 'noclick' : ''}`"
                                href="javascript:;" @click="handleAddressAction(ADDRESS_ACTION_TYPE.MOVE_BCC)">
                                <span>{{ $t("zimbra.zmMsg.moveToBcc") }}</span>
                            </a>
                        </li>
                    </div>
                </ul>
                <perfect-scrollbar style="max-height: 400px" v-if="!showMenuAction">
                    <el-menu ref="rightMenu" class="menu-list-members">
                        <el-menu-item class="menu-item" @click="selectAllMembers(null, listMembers)">
                            <span>{{
                                $t("zimbraNokey.zimbra_sidebar_dialogShare_selectAll", {
                                    total: listMembers.length,
                                })
                            }}</span>
                        </el-menu-item>
                        <el-menu-item v-for="item in listMembers" :key="item" @click="selectMembers(null, item)"
                            class="menu-item">
                            <i class="fas fa-user-tag"></i>
                            <span class="pl-2">{{ item }}</span>
                        </el-menu-item>
                    </el-menu>
                </perfect-scrollbar>
            </div>
        </context-menu>
    </div>
</template>
<script>
import contextMenu from "vue-context-menu";
import ItemAddressSelector from "@/components/modules/base/base-address-selector/ItemAddressSelector";
import ZimbraMailService from "@/services/ZimbraMailService.js";
import BaseAddressPopover from "@/components/modules/base/base-address-popover/BaseAddressPopover";
import CommonUtils from "@/utils/common-utils";
import VueTagsInput from "@johmun/vue-tags-input";
import { ADDRESS_ACTION_TYPE } from "@/utils/constants";
import { mapActions, mapGetters, mapState } from "vuex/dist/vuex.common.js";
import elOffset from "core-js/internals/array-reduce";
global.jQuery = require("jquery");
const $ = global.jQuery;
window.$ = $;
export default {
    name: "AddressSelector",
    mixins: [CommonUtils],
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: "",
        },
        showCc: {
            type: Boolean,
            default: true,
        },
        maxTag: {
            type: Number,
            default: 0
        },
        showBcc: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showDialog: {
            type: Boolean,
            default: true
        },
        allowDuplicate: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: "",
        },
    },
    components: {
        VueTagsInput,
        BaseAddressPopover,
        ItemAddressSelector,
        contextMenu
    },
    watch: {
        value() {
            this.tags = this.value ? this.value.filter(x => this.validateEmail(x.a)) : [];
            const mailErr = this.value ? this.value.find(x => !this.validateEmail(x.a)) : null;
            if (mailErr) {
                this.tag = mailErr.a;
            }
            this.setTabIndex();

        },
        tags(val) {
            if (!document) {
                return;
            }
            const input = document.querySelector(".ti-new-tag-input.ti-valid")
            if (this.maxTag && val.length == this.maxTag) {
                input.setAttribute('readonly', true);
            } else {
                // this.$refs.inputTag.disabled = false;
                input.removeAttribute('readonly');
            }
            if (val) {
                this.$refs.inputTag.$el.addEventListener("contextmenu", this.menuAction)
            } else {
                this.$refs.inputTag.$el.removeEventListener("contextmenu", this.menuAction)
            }
            this.setTabIndex();
        },
        tag(val) {
            this.$emit("changeText", val);
            if (this.timerSearch) {
                clearTimeout(this.timerSearch);
            }
            this.timerSearch = setTimeout(() => {
                this.handleSearch();
            }, 500);
        },
        filteredItems(val) {
            val.forEach(x => {
                this.mapValueWithObject.set(x.email, x);
            });
        },
    },
    data() {
        return {
            dialogFormVisible: false,
            tag: "",
            tags: this.value || [],
            filteredItems: [],
            timerSearch: null,
            showLoading: false,
            tagAction: null,
            spamNodeTag: null,
            mapValueWithObject: new Map(),
            tagActionIsGroup: false,
            showMenuAction: true,
            listMembers: [],
            eventAction: null,
            ADDRESS_ACTION_TYPE: ADDRESS_ACTION_TYPE,
            fullValueTag: {},
        };
    },
    asyncComputed: {
        // async filteredItems() {
        //     if (!this.tag || this.tag.lengh == 0) {
        //         return [];
        //     }
        //     const formData = {
        //         name: this.tag,
        //     };
        //     const searchRequest = await ZimbraMailService.autoCompleteRequest(
        //         formData
        //     );
        //     let { match } =
        //         this.getResponseBody(searchRequest)["AutoCompleteResponse"] || {};
        //     match = Array.isArray(match) ? match : (match ? [match] : []);
        //     return match
        //         ? match.map((e) => {
        //               return {
        //                   ...e,
        //                   text: e.email || e.display || this.$t("zimbra.zhMsg.noName"),
        //                   p: e.full || e.first,
        //                   a: this.getAddressEmailFromText(e.email || ''),
        //               };
        //           })
        //         : [];
        // },
    },
    created() {
        setTimeout(() => {
            this.$refs.inputTag.$el.addEventListener("contextmenu", this.menuAction);
            if (this.value) {
                this.value.forEach(x => {
                    this.mapValueWithObject.set(x.email, x);
                })
            }
        }, 200)
    },

    methods: {
        ...mapActions(['setEmailDragEvent']),
        disabledAction(actionType) {
            return actionType == this.type;
        },
        menuAction(event) {
            this.eventAction = event;
            if (event.target.classList.contains("el-popover__reference")) {
                event.preventDefault();
                if (this.tagAction && this.tagAction.classList) {
                    this.tagAction.classList.remove("tag-action");
                }
                this.showMenuAction = true;
                this.tagAction = event.target.parentElement
                setTimeout(() => {
                    event.target.parentElement.classList.add("tag-action");
                }, 1)
                // $(this.tagAction)[0].classList.add("tag-action")
                this.$refs.ctxMenu.open(event, {
                    source: this.tagAction.textContent.trim(),
                });
            }
        },
        onCtxClose() {
            this.tagAction.classList.remove("tag-action");
        },
        onCtxOpen(locals) {
            this.fullValueTag = this.mapValueWithObject.get(locals.source) || {};
            if (this.fullValueTag) {
                this.tagActionIsGroup = this.fullValueTag.isGroup;
            }
        },
        handleAddressAction(actionType) {
            switch (actionType) {
                case ADDRESS_ACTION_TYPE.DELETE:
                    this.tags = this.tags.filter(x => x.id != this.fullValueTag.id);
                    break
                case ADDRESS_ACTION_TYPE.COPY:
                    this.copyToClipboard(this.fullValueTag.email);
                    break
                case ADDRESS_ACTION_TYPE.EDIT: {
                    const inputNode = document.createElement("input");
                    inputNode.value = this.tagAction.textContent;
                    inputNode.id = "idInputEdit";
                    inputNode.className = "input-edit";
                    this.spamNodeTag = this.tagAction.childNodes[0].childNodes[0];
                    this.tagAction.childNodes[0].removeChild(this.tagAction.childNodes[0].childNodes[0]);
                    inputNode.blur(function () {
                        this.tagAction.removeChild(inputNode);
                    })
                    this.tagAction.childNodes[0].appendChild(inputNode);
                    setTimeout(() => {
                        inputNode.focus();
                        inputNode.addEventListener('blur', this.handleEditTag)
                    }, 200)
                    break
                }
                case ADDRESS_ACTION_TYPE.EXTEND:
                    this.openListMembers();
                    this.showMenuAction = false;
                    this.$refs.ctxMenu.open(this.eventAction, {
                        source: this.tagAction.textContent,
                    });
                    break
                case ADDRESS_ACTION_TYPE.EDIT_CONTACT: {
                    const _currentActiveMail = {
                        from: [{
                            a: this.fullValueTag.a,
                            email: this.fullValueTag.email,
                            text: this.fullValueTag.text
                        }]
                    };
                    const path = "/mail/contacts";
                    this.$router.push(path)
                    // window.location.href = "/mail/contacts";
                    this.addLocalStorageObject("editContactDataForm", { _currentActiveMail: _currentActiveMail, isEdit: true })
                    break;
                }
                case ADDRESS_ACTION_TYPE.MOVE_FROM:
                case ADDRESS_ACTION_TYPE.MOVE_CC:
                case ADDRESS_ACTION_TYPE.MOVE_BCC:
                    this.tags = this.tags.filter(x => x.id != this.fullValueTag.id);
                    // eslint-disable-next-line no-case-declarations
                    const to = actionType == ADDRESS_ACTION_TYPE.MOVE_FROM ? 'to' : actionType == ADDRESS_ACTION_TYPE.MOVE_CC ? 'cc' : 'bcc';
                    this.$emit("moveAddress", to, this.fullValueTag);
                    break;
            }
            this.$emit("change", this.tags);
        },
        handleEditTag() {
            const inputNode = document.getElementById("idInputEdit");
            var idx = this.tags.findIndex(x => x.id == this.fullValueTag?.id || x.a == this.fullValueTag?.a);
            this.tags[idx].email = inputNode.value;
            const startIndexEmail = inputNode.value.indexOf('<');
            const endIndexEmail = inputNode.value.indexOf('>');
            if (startIndexEmail < -1 || endIndexEmail < -1 || endIndexEmail < startIndexEmail) {
                this.tags[idx].a = inputNode.value;
            } else {
                this.tags[idx].a = inputNode.value.substring(startIndexEmail + 1, endIndexEmail)
            }
            this.mapValueWithObject.set(this.tags[idx].email.trim(), this.tags[idx])
            this.tagAction.childNodes[0].removeChild(inputNode);
            this.spamNodeTag.textContent = inputNode.value;
            this.tagAction.childNodes[0].appendChild(this.spamNodeTag);

        },
        openListMembers() {
            const content = this.fullValueTag.a;
            const form = { dl: { content: `${content}` }, limit: 100, offset: 0 };
            ZimbraMailService.getDistributionListMembersRequest(form)
                .then((res) => {
                    const data =
                        this.getResponseBody(res)[
                        "GetDistributionListMembersResponse"
                        ];
                    if (data && data.dlm) {
                        this.listMembers = Array.isArray(data.dlm)
                            ? data.dlm
                            : [data.dlm];
                    }
                })
                .catch();
        },
        setTabIndex() {
            $('li.ti-tag').attr('tabindex', '-1');
            $('a.el-popover__reference').attr('tabindex', '-1');
        },
        addTags(newTags) {
            newTags = newTags || [];
            // newTags.forEach((e) => {
            //     const exists = this.tags.find((x) => x.a == e.a);
            //     if (!exists) {
            //         this.tags.push(e);
            //     }
            // });
            this.tags = newTags;
            this.$emit("change", this.tags);
            this.setTabIndex();
        },
        openDialogAddressSeletor() {
            this.$emit("openDialogAddressSeletor", null);
        },
        tagsChange(newTags) {
            this.tags = newTags;
            this.$emit("change", this.tags);
            // this.$refs.inputTag.focus();
            if (this.$refs.inputTag && this.$refs.inputTag.$el) {
                const $ = window.jQuery;
                const $el = $(this.$refs.inputTag.$el);
                const $inputWrapper = $el.find('input.ti-new-tag-input');
                if ($inputWrapper) $inputWrapper.focus();
            }
            this.setTabIndex();
        },
        getEmailAddress(text) {
            const arr = text.split('" <');
            return arr[1].substring(0, arr[1].length - 1);
        },
        checkTag(obj) {
            if (obj.tag.a && this.validateEmail(obj.tag.a)) {
                obj.tag.p = obj.tag.p || this.$t("zimbra.zhMsg.noName")
                obj.tag.a = obj.tag.a || obj.tag.text;
                obj.addTag();
            } else if (obj.tag.text && this.validateEmail(obj.tag.text)) {
                obj.tag.p = obj.tag.text;
                obj.tag.a = obj.tag.text;
                obj.addTag();
                this.tags.push({
                    a: obj.tag.text,
                    text: obj.tag.text,
                });
                this.$refs.inputTag.newTag = "";
                this.$emit("changeText", "");
                this.$emit("change", this.tags);
            } else {
                this.filteredItems = [];
                this.tag = "";
                this.focus();
            }
        },
        beforeSavingTag(obj) {
            obj.saveTag();
        },
        selectMembers(props, selectedItem) {
            this.tags.push({
                a: selectedItem,
                text: selectedItem,
            });
            this.$refs.inputTag.performBlur();
            this.$refs.inputTag.newTag = "";
            this.$emit("changeText", "");
            this.$emit("change", this.tags);
        },
        selectAllMembers(props, listMembers) {
            if (listMembers && listMembers.length > 0) {
                listMembers.forEach(element => {
                    this.tags.push({
                        a: element,
                        text: element,
                    });
                });
                this.$refs.inputTag.performBlur();
                this.$refs.inputTag.newTag = "";
                this.$emit("changeText", "");
                this.$emit("change", this.tags);
            }
        },
        handleSearch() {
            if (!this.tag || this.tag.lengh == 0) {
                return [];
            }
            const formData = {
                name: this.tag,
            };
            this.showLoading = true;
            this.filteredItems = [];
            this.ajustAutocompleteDropDown();
            ZimbraMailService.autoCompleteRequest(
                formData
            ).then(searchRequest => {
                this.showLoading = false;
                let { match } =
                    this.getResponseBody(searchRequest)["AutoCompleteResponse"] || {};
                match = Array.isArray(match) ? match : (match ? [match] : []);
                this.filteredItems = match
                    ? match.map((e) => {
                        return {
                            ...e,
                            text: e.email || e.display || this.$t("zimbra.zhMsg.noName"),
                            p: e.full || e.first,
                            a: this.getAddressEmailFromText(e.email || ''),
                            showExpand: true
                        };
                    })
                    : [];
                this.ajustAutocompleteDropDown();
            }).catch(() => {
                this.showLoading = false;
            });
        },
        ajustAutocompleteDropDown() {
            const $ = window.jQuery;
            const $el = $(this.$refs.inputTag.$el);
            const $inputWrapper = $el.find('.ti-new-tag-input-wrapper');
            // const elOffset = $el.offset();
            const inputOffset = $inputWrapper.offset();
            setTimeout(() => {
                if (this.$refs.inputTag?.autocompleteOpen) {
                    const $autocomplete = $el.find('.ti-autocomplete');
                    // $autocomplete.css('left', (inputOffset.left - elOffset.left) + 'px')
                    // $autocomplete.css('margin-top', (inputOffset.top - elOffset.top) + 'px')
                    $autocomplete.css('left', (inputOffset.left) + 'px');
                    $autocomplete.css('top', (inputOffset.top + $inputWrapper.outerHeight()) + 'px');
                    $autocomplete.css('position', 'fixed');
                }
            }, 10)
        },
        focus() {
            this.$refs.inputTag.$el.querySelector('.ti-new-tag-input.ti-valid').focus();
        },

        onDrop(event) {
            event.preventDefault()
            if (event.dataTransfer.getData('type') !== this.type) {
                const value = event.dataTransfer.getData('emailDragging');
                this.tags.push({ text: value, a: value });
                this.$refs.inputTag.performBlur();
                this.$refs.inputTag.newTag = "";
                this.$emit("changeText", "");
                this.$emit("change", this.tags);
                this.setEmailDragEvent(event)
            }
        },
    }
};
</script>

<style>
.vue-tags-input {
    width: 100%;
    border: unset !important;
    border-radius: 0.6rem;
    max-width: 100% !important;
}

.ti-input {
    width: 100%;
    border-radius: 0.6rem;
    border: unset !important;
}

.ti-tag {
    float: left;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /* height: 2rem; */
    background-color: #f7f7f7 !important;
    margin-bottom: 0.5rem;
    margin-right: 0.6rem;
    padding: 0.2rem 1.2rem 0.2rem 0.8rem;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: #3f4254 !important;
}

.row-input-email .group-email {
    padding: unset !important;
}
</style>
